import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorsTable({ errors = [] }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Row</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {errors.map(row => (
            <tr key={row.rowNumber}>
              <td>{row.rowNumber}</td>
              <td>{row.error}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ErrorsTable.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.string.isRequired,
      value: PropTypes.string,
      cmsErrorKeys: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired
};
