import { put } from 'redux-saga/effects';

import { loadStudentTasksSuccess, loadStudentTasksFailure } from '../../../../actions/placementTests';
import getStudentTasks from '../../../apiCalls/hub/getStudentsTasks';

function* loadStudentTasks(payload) {
  const { status } = payload;

  const response = yield getStudentTasks({ status });

  if (response.status === 'success') {
    yield put(loadStudentTasksSuccess(response.data));
  } else {
    yield put(loadStudentTasksFailure([]));
  }
}

export default loadStudentTasks;
