import { select, put } from 'redux-saga/effects';
import checkUsernameBulk from '../../../../api/checkUsernameBulk';

import { setGenericFieldValidation, setUsernameAvailabilityStatus } from '../../../../../reducers/enrolUser.reducer.js';

export default function* checkUsernameAvailability({ id, orgId, key, value, validationPattern }) {
  console.log('[enrolUser Saga] checkUsernameAvailability');

  // -- genericValidation
  yield put(setGenericFieldValidation(id, key, value, validationPattern));

  const { entries, entry } = yield select(state => ({
    entries: state.enrolUser.entries,
    entry: state.enrolUser.entries.find(el => el.temp_id === id)
  }));

  if (entry.validationErrors[key].badEncoding || entry.validationErrors[key].defaultValidation || !value) return;

  // -- find in list of entries
  const { userName } = entry;
  const duplicatedRecordsInList = entries.filter(el => el.userName === userName);

  // -- set loading status
  yield put(setUsernameAvailabilityStatus(id, true, false));

  if (duplicatedRecordsInList.length > 1) {
    yield put(setUsernameAvailabilityStatus(id, false, false));
    return;
  }

  const responses = yield checkUsernameBulk({ userNames: [userName] }, orgId);

  if (responses.data.length === 0) {
    yield put(setUsernameAvailabilityStatus(id, false, true));
    return;
  }

  // -- is taken
  yield put(setUsernameAvailabilityStatus(id, false, false));
}
