import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentPlayerHeader from '../ContentPlayerHeader/ContentPlayerHeader';
import ContentPlayerBody from '../ContentPlayerBody/ContentPlayerBody';
import ContentPlayerFooter from '../ContentPlayerFooter/ContentPlayerFooter';
import { selfSelectedUserRoles, role } from '../../globals/cptConstants';
import { isOcpMode } from '../../utils/platform';
import {
  setContent,
  setCurrentContent,
  setTitles,
  clearCurrentContent,
  getXApiLaunchTokenRequest
} from '../../redux/actions/structuredContentPlayer';
import data01 from '../../../static/cptMockData/demo-01.json';
import data02 from '../../../static/cptMockData/demo-02.json';
import styles from './StructuredContentPlayer.scss';
import withLocalizedContent from '../../language/withLocalizedContent';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { getActivityLink } from '../../globals/urls';
import changeRoleToProductVariant from './structuredContentPlayerUtils';
import { openMissingLicenseModal } from '../../redux/actions/hubUi';
import withFocusTrap from '../../decorators/withFocusTrap';

const getData = dataName => (dataName === 'demo-01' ? data01 : data02);

// TODO Remove this method when the products will be fully configured and visible in Dashboard
export const prepareProductListItem = selfRole => {
  const dataArr = ['demo-01', 'demo-02'];
  const products = {};
  dataArr.forEach(dataName => {
    const responseData = getData(dataName);
    products[dataName] = {
      id: dataName,
      isbn: responseData.isbn,
      description: null,
      title: responseData.title,
      series: null,
      author: null,
      cefrLevel: null,
      expiryDate: '2024-12-31T08:19:56.897Z',
      productLaunchUrl: `/launch/${changeRoleToProductVariant(selfRole)}/${dataName}`,
      deepLinkUrl: '',
      vstDomain: null,
      productUserRole: null,
      hasLicence: true,
      readyToLaunch: true,
      comingSoonUntil: null,
      image: {
        title: responseData.title,
        altText: responseData.title,
        defaultUrl: `${window.location.origin}/060a57c67534db3224389aeed12ef62c.jpg`
      },
      hasAdditionalResources: false,
      notStartedLicence: false
    };
  });

  const productsArray = [];
  const keys = Object.keys(products);
  for (let i = 0; i < keys.length; i += 1) {
    productsArray.push(products[keys[i]]);
  }
  return productsArray;
};

function StructuredContentPlayer({
  subtitle,
  title,
  params,
  closePanel,
  setContentFromData,
  setTitlesFromData,
  localizedContent: { cptGlossary: cptContent },
  cptContentData,
  setCurrentContentData,
  clearCurrentContentData,
  modalBasePathLink,
  getXApiLaunchTokenRequestData,
  productVariant,
  openMissingLicenseModalAction,
  navigation,
  offlineUnits,
  // eslint-disable-next-line no-unused-vars
  isFocusTrapDisabled = false
}) {
  const {
    currentActivity,
    isFirstActivityInLesson,
    isLastActivityInLesson,
    previousActivity,
    nextActivity
  } = navigation;
  const [, setMenuRendered] = useState(false);
  const parentRef = useRef();
  const { userId: offlineUserId } = useParams();

  useEffect(() => {
    const url = new URL(window.location);
    if (cptContentData) {
      const { contents, activitiesMapping, ...rest } = cptContentData;
      const response = {
        ...rest,
        levelContents: contents
      };

      if (cptContentData.hasLicense) {
        setTitlesFromData(response);
        setContentFromData(response);
        let activityId = url.searchParams.get('activityId') || url.searchParams.get('nodeLocation');
        const nodeId = url.searchParams.get('node');
        const uId = url.searchParams.get('uId');
        if (uId) {
          let lastChild = contents.children[uId];
          while (lastChild.children && Object.keys(lastChild.children).length > 0) {
            lastChild = Object.values(lastChild.children)[0];
          }
          activityId = lastChild.activities[0].id;
        }

        if (nodeId && !activityId) {
          activityId = activitiesMapping[nodeId] || null;
        }

        if (activityId) {
          setCurrentContentData(activityId);
        } else {
          clearCurrentContentData(null);
        }
      } else {
        openMissingLicenseModalAction();
      }
    }
  }, [cptContentData, window.location.search]);

  useEffect(() => {
    if (currentActivity) {
      getXApiLaunchTokenRequestData({
        activityId: currentActivity,
        contextCode: params.contentCode,
        version: productVariant === selfSelectedUserRoles.SELF_TEACHER ? role.TEACHER : role.STUDENT
      });
    }
  }, [currentActivity]);

  let isNavigationLeftDisabled = !previousActivity;
  let isNavigationRightDisabled = !nextActivity;
  const isNextLocked = navigation.disableNext || false;
  const isPreviousLocked = navigation.disablePrevious || false;
  const isNextActivityDownloaded =
    nextActivity && offlineUnits.some(unit => nextActivity.parentId.includes(unit.id) && unit.userId === offlineUserId);

  const isPreviousActivtiyDownloaded =
    previousActivity &&
    offlineUnits.some(unit => previousActivity.parentId.includes(unit.id) && unit.userId === offlineUserId);

  if (isOcpMode()) {
    isNavigationLeftDisabled = isNavigationLeftDisabled || !isPreviousActivtiyDownloaded;
    isNavigationRightDisabled = isNavigationRightDisabled || !isNextActivityDownloaded;
  }

  const prevLink =
    !isNavigationLeftDisabled && !isPreviousLocked ? getActivityLink(modalBasePathLink, previousActivity?.id) : null;
  const nextLink =
    !isNavigationRightDisabled && !isNextLocked ? getActivityLink(modalBasePathLink, nextActivity?.id) : null;
  const hasNavigation = currentActivity !== '';

  let content = '';
  if (cptContentData) {
    if (cptContentData.hasLicense) {
      content = (
        <div className={styles.cpModal} ref={parentRef}>
          <div className={styles.cpModalContent}>
            <header className={styles.cpModalHeader}>
              <ContentPlayerHeader
                subtitle={subtitle}
                hasNavigation={hasNavigation}
                title={title}
                closePanel={closePanel}
                cptContent={cptContent}
                navigation={navigation}
              />
            </header>
            <ContentPlayerBody
              isPresentation={!hasNavigation}
              cptContent={cptContent}
              navigation={navigation}
              parentRef={parentRef}
              title={title}
              setMenuRendered={setMenuRendered}
            />
            {hasNavigation && (
              <footer className={styles.cpModalFooter}>
                <ContentPlayerFooter
                  cptContent={cptContent}
                  prevLink={prevLink}
                  nextLink={nextLink}
                  isDisabledLeft={isNavigationLeftDisabled || isPreviousLocked}
                  isDisabledRight={isNavigationRightDisabled || isNextLocked}
                  isFirstActivityInLesson={isFirstActivityInLesson}
                  isLastActivityInLesson={isLastActivityInLesson}
                />
              </footer>
            )}
          </div>
        </div>
      );
    }
  } else {
    content = <LoadingSpinner />;
  }

  return content;
}

StructuredContentPlayer.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.object,
  closePanel: PropTypes.func.isRequired,
  setContentFromData: PropTypes.func,
  setTitlesFromData: PropTypes.func,
  localizedContent: PropTypes.object,
  modalBasePathLink: PropTypes.object,
  cptContentData: PropTypes.object,
  setCurrentContentData: PropTypes.func,
  clearCurrentContentData: PropTypes.func,
  currentActivity: PropTypes.string,
  getXApiLaunchTokenRequestData: PropTypes.func,
  productVariant: PropTypes.string,
  openMissingLicenseModalAction: PropTypes.func,
  navigation: PropTypes.object,
  isFocusTrapDisabled: PropTypes.bool,
  offlineUnits: PropTypes.array
};

const mapStateToProps = state => ({
  title: state.structuredContentPlayer.title,
  subtitle: state.structuredContentPlayer.subtitle,
  modalBasePathLink: state.structuredContentPlayer.modalBasePathLink,
  cptContentData: state.structuredContentPlayer.cptContent,
  productVariant: state.structuredContentPlayer.productVariant,
  navigation: state.structuredContentPlayer.navigation,
  offlineUnits: state.offlineContentPlayer.units
});

const mapDispatchToProps = {
  setContentFromData: setContent,
  setCurrentContentData: setCurrentContent,
  setTitlesFromData: setTitles,
  clearCurrentContentData: clearCurrentContent,
  getXApiLaunchTokenRequestData: getXApiLaunchTokenRequest,
  openMissingLicenseModalAction: openMissingLicenseModal
};

const TrappedStructuredContentPlayer = withFocusTrap(StructuredContentPlayer);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLocalizedContent('cptGlossary')
)(TrappedStructuredContentPlayer);
