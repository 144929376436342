import { merge } from 'lodash';
import PersistentStorage from './Persistent';
import SessionStorage from './Session';

class WebStorage {
  constructor(key) {
    this.sessionStorage = new SessionStorage(key);
    this.persistentStorage = new PersistentStorage(key);
  }

  merge(data) {
    this.persistentStorage.set(merge({}, this.persistentStorage.get() ?? {}, data));
    this.sessionStorage.set(merge({}, this.sessionStorage.get() ?? {}, data));
  }

  get() {
    return merge({}, this.persistentStorage.get() ?? {}, this.sessionStorage.get() ?? {});
  }

  clear() {
    this.persistentStorage.clear();
    this.sessionStorage.clear();
  }
}

export default WebStorage;
