import { PLACEMENT_TEST_STATUS } from '@oup/shared-node-browser/constants';
import * as t from '../actionTypes';
import transformUser from '../utils/transformUser';

const mapStudentsInSessionResponse = students => {
  const result = {
    items: {},
    selectableIds: [],
    studentsAlreadyInClass: []
  };

  for (let i = 0; i < students.length; i += 1) {
    const user = students[i];
    const sessionLocked = user?.status === 'COMPLETED' || user?.status === 'STARTED';

    const userId = user._id;
    result.items[userId] = transformUser({
      username: user.userName,
      firstname: user.firstName,
      lastname: user.lastName,
      roleName: 'LEARNER',
      email: user.userEmail,
      testStartedAt: user.startedAt
    });
    if (!sessionLocked) {
      result.selectableIds.push(userId);
    }
    result.studentsAlreadyInClass.push(userId);
  }
  return result;
};

const countNumberOfTodoTasks = (oldCount, placementTests) => {
  const count = placementTests.reduce(
    (acc, test) =>
      test.configurations?.CONFIGURATION_PLACEMENT_TEST?.status === PLACEMENT_TEST_STATUS.ACTIVE ? acc + 1 : acc,
    0
  );

  return count || oldCount;
};

const requestStatusOptions = { loading: true, success: false, error: false };
const successStatusOptions = { loading: false, success: true, error: false };
const failureStatusOptions = { loading: false, success: false, error: true };

const initialState = {
  placementTests: [],
  refreshData: false,
  editJoiningCode: false,
  codeDetails: {},
  availableCredits: 0,
  joiningCodeUpdated: false,
  placesInTestSessionUpdated: false,
  errorResponse: '',
  studentsInTestSession: {},
  studentsSortedBy: '',
  studentTasks: {
    placementTests: [],
    numberOfTodoTasks: 0,
    totalNumberOfStudentTasks: 0
  }
};

function loadPlacementTestsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_PLACEMENT_TESTS:
      return {
        ...initialState,
        studentsInTestSession: state.studentsInTestSession,
        studentTasks: state.studentTasks,
        ...requestStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_SUCCESS:
      return {
        ...state,
        placementTests: payload,
        ...successStatusOptions
      };
    case t.GET_PLACEMENT_TESTS_FAILURE:
      return { ...state, ...failureStatusOptions };
    case t.REFRESH_PLACEMENT_TEST_LIST:
      return {
        ...state,
        refreshData: true,
        ...successStatusOptions
      };
    case t.OPEN_EDIT_JOINING_CODE:
      return { ...state, editJoiningCode: true, ...requestStatusOptions };

    case t.CLOSE_EDIT_JOINING_CODE:
      return { ...state, editJoiningCode: false };

    case t.GET_JOINING_CODE_DETAILS_SUCCESS:
      return {
        ...state,
        codeDetails: payload,
        ...successStatusOptions
      };
    case t.GET_JOINING_CODE_DETAILS_FAILURE:
      return { ...state, ...failureStatusOptions };

    case t.SUBMIT_EDIT_JOINING_CODE: {
      return { ...state, ...requestStatusOptions, errorResponse: '' };
    }

    case t.SUBMIT_EDIT_JOINING_CODE_SUCCESS:
      return { ...state, ...successStatusOptions, joiningCodeUpdated: true };

    case t.SUBMIT_EDIT_JOINING_CODE_FAILURE:
      return { ...state, ...failureStatusOptions, joiningCodeUpdated: false, errorResponse: payload };

    case t.EDIT_JOINING_CODE_UPDATED:
      return { ...state, joiningCodeUpdated: false };

    case t.UPDATE_PLACES_IN_TEST_SESSION:
      return { ...state, ...requestStatusOptions, errorResponse: '' };

    case t.UPDATE_PLACES_IN_TEST_SESSION_SUCCESS:
      return { ...state, ...successStatusOptions, placesInTestSessionUpdated: true };

    case t.UPDATE_PLACES_IN_TEST_SESSION_FAILURE:
      return { ...state, ...failureStatusOptions, placesInTestSessionUpdated: false, errorResponse: payload };
    case t.GET_TEST_AVAILABLE_CREDITS_SUCCESS:
      return { ...state, availableCredits: payload };
    case t.EDIT_JOINING_CODE_RESET_ERROR:
      return { ...state, errorResponse: '' };
    case t.GET_STUDENTS_IN_TEST_SESSION:
      return { ...state, ...requestStatusOptions };
    case t.GET_STUDENTS_IN_TEST_SESSION_SUCCESS:
      return {
        ...state,
        ...successStatusOptions,
        studentsInTestSession: {
          students: mapStudentsInSessionResponse(payload.students),
          totalNumberOfStudents: payload.totalNumberOfStudents
        }
      };

    case t.GET_STUDENTS_IN_TEST_SESSION_FAILURE:
      return { ...state, ...failureStatusOptions, studentsInTestSession: {}, errorResponse: payload };
    case t.SET_SORTING_FOR_STUDENTS:
      return {
        ...state,
        studentsSortedBy: payload
      };
    case t.GET_STUDENT_TASKS:
      return {
        ...state,
        studentTasks: {
          placementTests: [],
          totalNumberOfStudentTasks: 0,
          numberOfTodoTasks: state.studentTasks.numberOfTodoTasks
        },
        ...requestStatusOptions
      };
    case t.GET_STUDENT_TASKS_SUCCESS:
      return {
        ...state,
        studentTasks: {
          placementTests: payload.placementTests,
          totalNumberOfStudentTasks: payload.totalNumberOfTestsSessions,
          numberOfTodoTasks: countNumberOfTodoTasks(state.studentTasks.numberOfTodoTasks, payload.placementTests)
        },
        ...successStatusOptions
      };
    case t.GET_STUDENT_TASKS_FAILURE:
      return {
        ...state,
        studentTasks: {
          placementTests: [],
          totalNumberOfStudentTasks: 0,
          numberOfTodoTasks: 0
        },
        ...failureStatusOptions
      };

    default:
      return state;
  }
}

export default loadPlacementTestsReducer;
