import { select, put } from 'redux-saga/effects';

import bulkUsersValidationApi from '../../../../apiCalls/bulkUsersValidationApi.js';

import { showValidationErrorReview, showDataReview } from '../../../../../reducers/enrolUser.reducer.js';
import { getCurrentPlatform } from '../../../../../../utils/platform.js';

export default function* bulkUsersUploadRequest(payload) {
  console.log('[bulkReviewableUpload Saga] Uploading file contents...');

  const { orgId, orgRole, userTypeToEnrol, isPlacementCentre } = payload;
  const { fileContents, fileName, fileType } = yield select(state => ({
    fileContents: state.enrolUser.file.data,
    fileName: state.enrolUser.file.name,
    fileType: state.enrolUser.file.type
  }));

  const result = yield bulkUsersValidationApi(orgId, orgRole, isPlacementCentre, userTypeToEnrol, {
    orgId,
    fileContents,
    fileName,
    fileType,
    platformCode: getCurrentPlatform()
  });

  if (result.status === 'success') {
    yield put(showDataReview(result.data));
  } else {
    const errors = Object.entries(result.data).map(error => ({
      row: error[0],
      cmsErrorKeys: error[1]
    }));

    yield put(showValidationErrorReview(errors));
  }
}
