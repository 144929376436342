import { pick, isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selfDefinedUserTypes } from '@oup/shared-node-browser/user';
import { ORG_REGISTRATION_CONTEXT, ORG_STAFF, ORG_STUDENTS } from '@oup/shared-node-browser/constants.js';

import {
  formStates,
  resetForm,
  resetRoleValues,
  setContextValue,
  setRoleValue
} from '../../../../../../redux/reducers/enrolUser.reducer.js';
import EnrolUserBulkConfirmation from './EnrolUserBulkConfirmation.js';
import EnrolUserBulkErrorReviewing from './EnrolUserBulkErrorReviewing.js';
import EnrolUserValidationError from './EnrolUserValidationError/EnrolUserValidationError.js';
import EnrolUserBulkInputting from './EnrolUserBulkInputting.js';
import EnrolUserBulkSubmitting from './EnrolUserBulkSubmitting.js';
import EnrolUserConfirmationRestyled from './EnrolUserConfirmationRestyled.js';
import EnrolUserConfirmation from './EnrolUserConfirmation.js';
import EnrolUserInputting from './EnrolUserInputting.js';
import EnrolUserSubmitting from './EnrolUserSubmitting.js';
import EnrolUserAddToClass from './EnrolUserAddToClass.js';
import EnrolUserDataReviewing from './EnrolUserDataReviewing/EnrolUserDataReviewing.js';
import { featureIsEnabled, getPlatformSupportUrl } from '../../../../../../globals/envSettings';
import userRoles from '../../../../../../globals/userRoles.js';
import { isHubMode } from '../../../../../../utils/platform.js';
import EnrolUserBulkConfirmationRestyled from './EnrolUserBulkConfirmationRestyled.js';

const getAdminRightsSupportLink = subsMarket => {
  let link;
  const supportUrl = getPlatformSupportUrl('orb');
  const classAdministrRightLink = `teacher-support/class-administration/administration-rights`;
  switch (subsMarket) {
    case 'ASIA':
      link = `${supportUrl}as/${classAdministrRightLink}`;
      break;
    case 'AFRICA':
      link = `${supportUrl}za/${classAdministrRightLink}`;
      break;
    case 'AUSTRALASIA':
      link = `${supportUrl}au/${classAdministrRightLink}`;
      break;
    default:
      link = `${supportUrl}${classAdministrRightLink}`;
  }
  return link;
};
class EnrolUser extends React.Component {
  constructor() {
    super();
    this.state = {
      tempNewClassAdded: false
    };
  }

  resetRoleByAction = () => {
    const { enrolUserContext, setContext, context, setRole, roleValue, resetRole } = this.props;

    if (!enrolUserContext) setContext(context);

    if (context === 'ORG_STUDENTS') {
      setRole(userRoles.LEARNER);
    } else if (roleValue === userRoles.LEARNER) {
      // for cases when a student was attempted to be added using the 'Add Student' button but the panel
      // was closed and then a member of staff was attempted to be added using the 'Add Staff' button
      resetRole();
    }
  };

  getUserTypeToEnrolFromContext = () => {
    const { context } = this.props;

    if (context === ORG_STUDENTS) {
      return 'students';
    }

    if (context === ORG_STAFF) {
      return 'staff';
    }

    return null;
  };

  _handleClosePanel = () => {
    const { closePanel } = this.props;
    this.resetRoleByAction();
    closePanel();
  };

  _handleOnComplete = () => {
    const { onComplete, reset } = this.props;
    reset();
    onComplete();
  };

  _handleTempNewClassAdded = () => {
    this.setState({ tempNewClassAdded: true });
  };

  _handleOnAddOther = () => {
    const { reset } = this.props;
    this.setState({ tempNewClassAdded: false });
    reset();
  };

  render() {
    let content;
    const { tempNewClassAdded } = this.state;
    const {
      orgId,
      formState,
      context,
      subsMarket,
      displayBackButton,
      backAction,
      orgData = {},
      uploadContext = {},
      branch,
      className
    } = this.props;
    const administrationRightsSupportLink = getAdminRightsSupportLink(subsMarket);

    const shouldShowRestyledSuccessModal =
      isHubMode() && featureIsEnabled('supervised-users') && context === ORG_STUDENTS;

    const userTypeToEnrol =
      branch === ORG_REGISTRATION_CONTEXT.PLACEMENT || orgData?.isPlacementCentre
        ? this.getUserTypeToEnrolFromContext()
        : null;

    const reviewableBulkUploadOptions = {
      ...orgData,
      userTypeToEnrol: context
    };

    switch (formState) {
      case formStates.INPUTTING:
        content = (
          <EnrolUserInputting
            administrationRightsSupportLink={administrationRightsSupportLink}
            orgId={orgId}
            closePanel={this._handleClosePanel}
            context={context}
            displayBackButton={displayBackButton}
            backAction={backAction}
            userTypeToEnrol={userTypeToEnrol}
          />
        );
        break;
      case formStates.BULK_USER_INPUTTING:
        content = (
          <EnrolUserBulkInputting
            administrationRightsSupportLink={administrationRightsSupportLink}
            orgData={orgData}
            uploadContext={{ ...(uploadContext || {}), userTypeToEnrol, context }}
            closePanel={this._handleClosePanel}
          />
        );
        break;
      case formStates.ADD_TO_CLASS_VIEW:
        content = (
          <EnrolUserAddToClass
            closePanel={this._handleClosePanel}
            orgId={orgId}
            isTempNewClassAdded={tempNewClassAdded}
            handleTempNewClassAdded={this._handleTempNewClassAdded}
            userType={selfDefinedUserTypes.CLASS_TEACHER}
          />
        );
        break;
      case formStates.SUBMITTING:
        content = <EnrolUserSubmitting context={context} />;
        break;
      case formStates.BULK_SUBMITTING:
      case formStates.BULK_BY_USER_TYPE_SUBMITTING:
        content = <EnrolUserBulkSubmitting />;
        break;
      case shouldShowRestyledSuccessModal && formStates.CONFIRMATION:
        content = (
          <EnrolUserConfirmationRestyled
            closePanel={this._handleClosePanel}
            {...pick(this.props, [
              'failed',
              'invitePending',
              'userExists',
              'userHasNamesSet',
              'apiError',
              'roleName',
              'name',
              'organisationName',
              'classesOnboardFailed',
              'email',
              'signInCardUrl',
              'usernameGenerated',
              'usernameWithoutOrg'
            ])}
            context={context}
            onComplete={this._handleOnComplete}
            onAddOther={this._handleOnAddOther}
            userTypeToEnrol={userTypeToEnrol}
          />
        );
        break;
      case !shouldShowRestyledSuccessModal && formStates.CONFIRMATION:
        content = (
          <EnrolUserConfirmation
            closePanel={this._handleClosePanel}
            {...pick(this.props, [
              'failed',
              'invitePending',
              'userExists',
              'userHasNamesSet',
              'apiError',
              'roleName',
              'name',
              'organisationName',
              'classesOnboardFailed',
              'email'
            ])}
            context={context}
            onComplete={this._handleOnComplete}
            onAddOther={this._handleOnAddOther}
          />
        );
        break;
      case shouldShowRestyledSuccessModal && formStates.BULK_CONFIRMATION:
        content = (
          <EnrolUserBulkConfirmationRestyled
            closePanel={this._handleClosePanel}
            onComplete={this._handleOnComplete}
            userTypeToEnrol={userTypeToEnrol}
          />
        );
        break;
      case !shouldShowRestyledSuccessModal && formStates.BULK_CONFIRMATION:
        content = <EnrolUserBulkConfirmation closePanel={this._handleClosePanel} onComplete={this._handleOnComplete} />;
        break;
      case formStates.BULK_ERROR_REVIEWING:
        content = <EnrolUserBulkErrorReviewing closePanel={this._handleClosePanel} userTypeToEnrol={userTypeToEnrol} />;
        break;
      case formStates.BULK_VALIDATION_ERROR_REVIEWING:
        content = <EnrolUserValidationError closePanel={this._handleClosePanel} />;
        break;
      case formStates.BULK_DATA_REVIEWING:
        content = <EnrolUserDataReviewing closePanel={this._handleClosePanel} options={reviewableBulkUploadOptions} />;
        break;
      default:
        content = (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }

    return <div className={className}>{content}</div>;
  }
}

EnrolUser.propTypes = {
  orgId: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  roleName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  apiError: PropTypes.object,
  failed: PropTypes.bool.isRequired,
  invitePending: PropTypes.bool,
  userExists: PropTypes.bool,
  userHasNamesSet: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  enrolUserContext: PropTypes.string,
  usernameGenerated: PropTypes.bool,
  usernameWithoutOrg: PropTypes.string,
  signInCardUrl: PropTypes.string,
  errorType: PropTypes.string,
  missingColumns: PropTypes.array,
  maximumAllowedRecords: PropTypes.number,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  orgRole: PropTypes.string,
  subsMarket: PropTypes.string,
  resetRole: PropTypes.func.isRequired,
  setRole: PropTypes.func.isRequired,
  roleValue: PropTypes.string,
  displayBackButton: PropTypes.string,
  backAction: PropTypes.func,
  orgData: PropTypes.shape({
    orgId: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    orgRole: PropTypes.string.isRequired,
    isPlacementCentre: PropTypes.bool.isRequired
  }),
  branch: PropTypes.string,
  uploadContext: PropTypes.shape({
    groupId: PropTypes.string,
    groupType: PropTypes.string
  }),
  className: PropTypes.string
};
export default connect(
  (state, { orgId }) => {
    const { enrolUser, subscriptions = {} } = state;
    const organisation = state.organisations.data[orgId] || {};
    const orgRole = organisation.role;
    const subsMarket = Object.values(subscriptions)[0]?.productDetails?.market;
    const {
      orgConfig: { configurations: { CONFIG_PLACEMENT_CENTER: placementCentreDetails = null } = {} } = {}
    } = organisation;

    const classId =
      state.placementTestSessionCreate?.placementTestSessionId ||
      state.placementOnboardingWizard?.placementTestSessionIdCreated;

    const uploadContext = {
      groupType: classId ? 'placement-group' : 'placement-centre',
      groupId: classId || orgId
    };

    return {
      ...pick(enrolUser, [
        'formState',
        'failed',
        'invitePending',
        'userExists',
        'userHasNamesSet',
        'apiError',
        'classesOnboardFailed',
        'usernameWithoutOrg',
        'signInCardUrl',
        'usernameGenerated',
        'errorType',
        'missingColumns',
        'maximumAllowedRecords'
      ]),
      roleName: enrolUser.roleValue,
      name: `${enrolUser.firstNameValue} ${enrolUser.lastNameValue}` || '',
      email: enrolUser.emailValue,
      organisationName: organisation.name || '',
      orgRole,
      subsMarket,
      orgData: {
        orgId,
        orgName: organisation.name || '',
        orgRole,
        isPlacementCentre: isPlainObject(placementCentreDetails)
      },
      uploadContext
    };
  },
  {
    reset: resetForm,
    resetRole: resetRoleValues,
    setContext: setContextValue,
    setRole: setRoleValue
  }
)(EnrolUser);
