/* eslint-disable default-case */
import { PLATFORMS } from '@oup/shared-node-browser/constants.js';

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
// Utils
import { sanitizeUrl } from '../../../utils/url';
import openProductPage from '../../../utils/openProductPage';
import getProductUrl from '../../../utils/getProductUrl';
// Style
import styles from '../ListItems.scss';
// Constants
import {
  licenceStatus as LICENCE_STATUS,
  HubLayoutConstants,
  productInformationContext
} from '../../../globals/hubConstants.js';
import { featureIsEnabled, getPlatformBaseUrl } from '../../../globals/envSettings.js';
// Redux
import { openRedeemModal, openDownloadProductModal } from '../../../redux/actions/hubUi';
import { setModalCloseLink, loadCptContentRequest } from '../../../redux/actions/structuredContentPlayer';
// Components
import Link from '../../Link/Link';
import { HubFallbackImg as FallbackImage } from '../../HubImage';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import HubLabel from '../../HubLabel/HubLabel';
import HubLicenceStatusItem from '../../../structure/HubOrganizationLayout/OrganizationLicences/HubLicenceStatusItem';
// Services
import getUserLicencesStatusData from '../../../structure/HubOrganizationLayout/OrganizationLicences/services/getUserLicencesStatusData';
import getDropdownItems from '../../HubDropdownMenu/Services/getDropdownItems';
import HubDropdownMenu from '../../HubDropdownMenu/HubDropdownMenu';
import { getCurrentPlatform, isLtiMode, isHubMode } from '../../../utils/platform';
import { updateRecentlyAccessedRequest } from '../../../redux/actions/recentlyAccessed.js';
import { isTeacherInOrgOrSelfSelected } from '../../../globals/userRoles.js';

const { isTeacherOrAbove } = require('@oup/shared-node-browser/user.js');

// eslint-disable-next-line func-names
const ProductListItem = function({
  productId,
  product,
  hubContent,
  assignmentId,
  openRedeemModalAction,
  downloadIsLoading,
  downloadResourceId,
  breakpoint,
  dropdownActions: { toggleDetails },
  dropdownType = 'productList',
  setModalCloseLinkAction,
  updateRecentlyAccessedAction,
  isPreview = false,
  openDownloadProductModalAction,
  loadCptContentRequestAction,
  userRole
}) {
  const hasLicence = product.hasLicence;
  const licenceDetails = hasLicence ? { hasLicence: true, expiryDate: product.expiryDate } : {};
  if (product.notStartedLicence) {
    licenceDetails.licenceNotStartedDetails = product.licenceNotStartedDetails;
  }
  const statusDetails = getUserLicencesStatusData(licenceDetails, hubContent);
  const { status: licenceStatus } = statusDetails;

  const { EXPIRED, NO_LICENCE, ACTIVE, EXPIRING, NOT_STARTED } = LICENCE_STATUS;
  const isLicenseExpired = licenceStatus === EXPIRED || licenceStatus === NO_LICENCE;
  const isLicenseActive = licenceStatus === ACTIVE || licenceStatus === EXPIRING || licenceStatus === NOT_STARTED;
  const isProductReadyToLaunch = product.readyToLaunch;
  const isCourseNew = product.considered_as_new;
  const isStudentMaterial = product && product.productUserRole === HubLayoutConstants.COURSE_USER_ROLES.STUDENT;
  const isTeacherMaterial = product && product.productUserRole === HubLayoutConstants.COURSE_USER_ROLES.TEACHER;

  const actions = {
    toggleDetails: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleDetails(product, null, null, productInformationContext.MY_COURSES);
    },
    downloadProduct: e => {
      e.preventDefault();
      e.stopPropagation();

      const image = product.image.defaultUrl;
      const role = isTeacherOrAbove(product.productUserRole)
        ? HubLayoutConstants.TARGET_USERTYPE.TEACHER
        : HubLayoutConstants.TARGET_USERTYPE.STUDENT;

      loadCptContentRequestAction({ contentCode: product.contentCode, role, isOcp: true });
      openDownloadProductModalAction(product.contentCode, product.title, image, product.isbn);
    }
  };

  const expired = new Date(product.expiryDate) < new Date();

  const showDownloadProductOption =
    featureIsEnabled('hub-offline-downloads') &&
    product.offline_content &&
    isTeacherInOrgOrSelfSelected(userRole) &&
    product.productUserRole === HubLayoutConstants.TARGET_USERTYPE.TEACHER &&
    product.hasLicence &&
    !expired &&
    isHubMode();

  const dropdownOptions = {
    showDownloadProductOption
  };

  const getProductLink = () => {
    if (isLicenseExpired || isLtiMode()) {
      return null;
    }

    return product.productLaunchUrl;
  };

  const handleClick = (e, isReadytolaunch) => {
    let productUrl;
    let queryParams;

    const platform = getCurrentPlatform();
    const platformBaseUrl = getPlatformBaseUrl(platform);

    setModalCloseLinkAction(window.location.pathname);

    if (isLtiMode()) {
      return false;
    }

    if (!isReadytolaunch) {
      return false;
    }

    if (downloadIsLoading) {
      return false;
    }

    if (isLicenseExpired) {
      openRedeemModalAction();
      return true;
    }
    switch (true) {
      case product.platform === PLATFORMS.ELTCORE:
        e.preventDefault();
        e.stopPropagation();
        productUrl = getProductUrl(`${platformBaseUrl}/launch/${product.productUserRole}/${product.contentCode}`);
        break;
      case (product?.vstDomain || product.platform === 'VST') && isLicenseActive:
        e.preventDefault();
        e.stopPropagation();
        queryParams = {
          domain: product.vstDomain,
          assignmentId
        };
        productUrl = getProductUrl(`/product/${HubLayoutConstants.DOWNLOAD_TYPES.VST}/${product.isbn}`, queryParams);
        break;
    }
    updateRecentlyAccessedAction({
      url: productUrl || getProductLink(),
      type: 'product',
      title: product.title,
      imageUrl: product?.image?.defaultUrl
    });
    if (productUrl) {
      openProductPage(productUrl);
    }
    return true;
  };

  const getProductImage = () => {
    const image = get(product, ['image', 'defaultUrl'], '') || get(product, ['coverImage'], '');
    const altText = get(product, ['image', 'altText'], '') || product.title;
    return {
      image,
      altText
    };
  };
  const { image, altText } = getProductImage();

  return (
    <div data-list="product">
      <Link
        to={getProductLink()}
        onClick={e => handleClick(e, isProductReadyToLaunch)}
        className={classnames(styles.product, { [styles[licenceStatus] || '']: licenceStatus })}
      >
        <>
          <div className={styles.leftContainer}>
            <div className={styles.imgContainer}>
              {(isLicenseExpired || !isProductReadyToLaunch) && (
                <div className={styles.lockIconContainer}>
                  <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
                </div>
              )}
              {image ? (
                <img
                  className={isLicenseExpired || !isProductReadyToLaunch ? styles.lockedImage : ''}
                  src={sanitizeUrl(image)}
                  alt={`Course Material: ${altText}`}
                />
              ) : (
                <FallbackImage
                  className={isLicenseExpired || !isProductReadyToLaunch ? styles.lockedImage : ''}
                  customClassname="svgFullWidth"
                  breakpoint={breakpoint}
                />
              )}
            </div>
            <div className={styles.textContainer}>
              {(!isProductReadyToLaunch || isTeacherMaterial || isStudentMaterial) && (
                <div className={styles.labelsContainer}>
                  {!isProductReadyToLaunch && (
                    <HubLabel
                      text={hubContent.hub_label_coming_soon}
                      customClassName={styles.comingSoonLabel}
                      isCollapsed={false}
                      PrefixIcon={false}
                    />
                  )}
                  {isTeacherMaterial && (
                    <HubLabel
                      text={hubContent.hub_label_teacher_material}
                      customClassName={styles.labelPill}
                      isCollapsed={false}
                      PrefixIcon={false}
                    />
                  )}
                  {isStudentMaterial && (
                    <HubLabel
                      text={hubContent.hub_label_student_material}
                      customClassName={styles.labelPill}
                      isCollapsed={false}
                      PrefixIcon={false}
                    />
                  )}
                  {isCourseNew && (
                    <HubLabel
                      text={hubContent.hub_label_study_material_new_content}
                      customClassName={styles.newContentlabelPill}
                      isCollapsed={false}
                      PrefixIcon={false}
                    />
                  )}
                </div>
              )}
              <div className={classnames(styles.innerTextContainer, isLicenseActive ? styles.activeLinkText : '')}>
                <span>
                  {product.title}
                  {isLicenseActive && !isLtiMode() && <SVGIcon glyph={GLYPHS.ICON_LINK} />}
                  {product.isbn === downloadResourceId && downloadIsLoading && (
                    <SVGIcon className={styles.loading} glyph={GLYPHS.ICON_LOADING} />
                  )}
                </span>
                <span className="a11y-hide">{hubContent.ally_opens_in_new_tab}</span>
                {product.level && <p>{product.level}</p>}
                {product.hasAdditionalResources && (
                  <p className={styles.addResourcesFlag}>{hubContent.has_additional_resources}</p>
                )}
              </div>
            </div>
          </div>

          {!isPreview && (
            <div className={styles.statusLicenceContainer}>
              <HubLicenceStatusItem
                licencesContext={HubLayoutConstants.LICENCES_CONTEXT.MY_COURSES}
                statusDetails={statusDetails}
                hubContent={hubContent}
                styles={styles}
                breakpoint={breakpoint}
              />
            </div>
          )}
          <div className={styles.dropdownContainer}>
            <HubDropdownMenu
              dropdownData={getDropdownItems(dropdownType, hubContent, dropdownOptions, actions)}
              parentId={productId}
              customClassname={styles.dropDownMenu}
              tabindex={0}
            />
          </div>
        </>
      </Link>
    </div>
  );
};

const mapStateToProps = ({
  hubResourceDownload: { downloadIsLoading, downloadResourceId },
  userProfile: { role: userRole }
}) => ({
  downloadIsLoading,
  downloadResourceId,
  userRole
});

const mapDispatchToProps = {
  openRedeemModalAction: openRedeemModal,
  setModalCloseLinkAction: setModalCloseLink,
  updateRecentlyAccessedAction: updateRecentlyAccessedRequest,
  openDownloadProductModalAction: openDownloadProductModal,
  loadCptContentRequestAction: loadCptContentRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem);

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  hubContent: PropTypes.object,
  assignmentId: PropTypes.string,
  openRedeemModalAction: PropTypes.func,
  downloadIsLoading: PropTypes.bool,
  downloadResourceId: PropTypes.string,
  breakpoint: PropTypes.string,
  dropdownActions: PropTypes.object,
  dropdownType: PropTypes.string,
  productId: PropTypes.string,
  setModalCloseLinkAction: PropTypes.func,
  updateRecentlyAccessedAction: PropTypes.func,
  isPreview: PropTypes.bool,
  openDownloadProductModalAction: PropTypes.func,
  loadCptContentRequestAction: PropTypes.func,
  userRole: PropTypes.string
};
