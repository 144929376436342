import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
// Components
import SiteHeader from '@oup/shared-front-end/src/components/SiteHeader/SiteHeader.js';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_HELP_CIRCLE, ICON_USER } from '@oup/shared-front-end/src/svg/oup/index';
import withLocalizedContent from '../../language/withLocalizedContent.js';

function OLBLayout({ children }) {
  useEffect(() => {
    const THEME_NAME = 'olb-theme';
    document.body.setAttribute('data-theme', THEME_NAME);
  }, []);

  return (
    <div className="test">
      <div className="container">
        <SiteHeader
          buttons={[
            {
              type: Button,
              props: {
                variant: 'filled',
                icon: { component: <ICON_HELP_CIRCLE /> },
                text: 'Help and Support',
                onClick: () => window.open('https://dev.account.oup.com/', '_blank'),
                className: 'helpButtonSmall'
              }
            },
            {
              type: Button,
              props: {
                icon: { component: <ICON_USER className="helpIconLoggedOut" /> },
                text: 'Sign in'
              }
            },
            {
              type: Button,
              wrapperClass: 'registerButton',
              props: {
                text: 'Register today'
              }
            }
          ]}
          loggedIn={false}
          secondaryText="Oxford University Press"
        />
        <main id="maincontent">{children}</main>
      </div>
    </div>
  );
}

OLBLayout.propTypes = {
  children: PropTypes.any
};

export default compose(withLocalizedContent('siteHeaders'))(OLBLayout);
