import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { URL_HIERARCHY_DELIMITER, RESOURCE_TYPE } from '@oup/shared-node-browser/constants';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { featureIsEnabled } from '../../globals/envSettings';
import GradebookTable from '../GradebookTable/GradebookTable';
import Button, { buttonTypes } from '../Button/Button';
import formatFraction from './formatFraction';
import formatLastAccessed from '../../utils/date/formatLastAccessed';
import { RETURN_PAGE_TARGET } from '../../globals/appConstants';
import styles from './StudentRow.scss';
import { PLATFORMS, HubLayoutConstants } from '../../globals/hubConstants';
import openProductPage from '../../utils/openProductPage';
import getProductUrl from '../../utils/getProductUrl';
import PencilReviewButton from '../PencilReviewButton/PencilReviewButton';

export const getFormattedAttemptsLevels = (
  {
    isActivityLevel,
    levels,
    totalLevelsCompleted,
    totalLevelsScore,
    numberOfCompletedActivities,
    totalNumberOfActivities,
    totalLevelsScoreAvailable
  },
  usePercentages,
  attemptFilter
) => {
  const activitiesAttempted = Object.values(levels)
    .map(levelValue => levelValue.totalNumberOfAttempts === 0 && levelValue.totalScoreAchieved === 0)
    .includes(false);

  const totalAttempts = isActivityLevel
    ? formatFraction(numberOfCompletedActivities, levels.length, usePercentages)
    : formatFraction(totalLevelsCompleted, totalNumberOfActivities, usePercentages);

  const totalAttemptsFormatted = activitiesAttempted || isActivityLevel ? totalAttempts : '-';
  const totalLevelsScoreNumber = typeof totalLevelsScore === 'number' ? totalLevelsScore : 0;
  const totalLevels =
    totalLevelsCompleted === 0
      ? '-'
      : formatFraction(
          totalLevelsScoreNumber,
          totalLevelsScoreAvailable,
          usePercentages || attemptFilter === 'average'
        );
  const totalLevelsFormatted = activitiesAttempted ? totalLevels : '-';

  return { totalAttemptsFormatted, totalLevelsFormatted };
};

export const getLevelCompletionActivities = (
  {
    activityName,
    totalNumberOfAttempts,
    submittedNumberOfActivities,
    totalNumberOfActivities,
    totalScoreAchieved,
    levelScoreAchieved,
    totalScoreAvailable,
    levelScoreAvailable,
    allActivityLevelScoreAvailable,
    allActivityTotalScoreAvailable
  },
  isActivityLevel,
  usePercentages,
  rangeValue,
  useFilterByScore,
  attemptFilter,
  useCompletedOnly
) => {
  const submittedActivitiesOrAttempts = activityName ? totalNumberOfAttempts : submittedNumberOfActivities;
  const levelCompletionSlashAttempts = isActivityLevel
    ? totalNumberOfAttempts
    : formatFraction(submittedNumberOfActivities, totalNumberOfActivities, usePercentages);

  const denominatorIsActivity = useCompletedOnly ? totalScoreAvailable : allActivityTotalScoreAvailable;
  const denominator = useCompletedOnly ? levelScoreAvailable : allActivityLevelScoreAvailable;
  const levelSlashActivityScore =
    submittedActivitiesOrAttempts === 0
      ? '-'
      : formatFraction(
          isActivityLevel ? totalScoreAchieved : levelScoreAchieved,
          isActivityLevel ? denominatorIsActivity : denominator,
          usePercentages || attemptFilter === 'average'
        );

  const condition = isActivityLevel
    ? { achieved: totalScoreAchieved, available: denominatorIsActivity }
    : { achieved: levelScoreAchieved, available: denominator };

  const isHighlightScore =
    useFilterByScore && rangeValue && submittedActivitiesOrAttempts !== 0
      ? parseFloat(formatFraction(condition.achieved, condition.available, true)) <= parseInt(rangeValue, 10)
      : false;

  return { levelCompletionSlashAttempts, levelSlashActivityScore, isHighlightScore };
};

export const getUidDetails = (hash, uId) => {
  if (!hash || !uId) return {};
  const levelDetails = uId.split('-');
  const activityId = levelDetails[1];
  const bid = levelDetails[0].split(':').slice(1);
  return { bid, activityId };
};

const getParentUId = hierarchy => {
  const parentUIdWithTitle =
    hierarchy && hierarchy.includes(URL_HIERARCHY_DELIMITER) ? hierarchy.split(URL_HIERARCHY_DELIMITER).pop() : '';
  return parentUIdWithTitle.includes('~') ? parentUIdWithTitle.split('~')[0] : '';
};

// Function to handle the conditional logic for last accessed or last opened date
const getLastAccessedOrOpened = (showBlankValue, lastOpened, lastAccessed) => {
  if (showBlankValue) {
    return '-';
  }
  if (featureIsEnabled('replacing-last-accessed-with-last-opened') && lastOpened) {
    return formatLastAccessed(lastOpened);
  }
  return formatLastAccessed(lastAccessed);
};

class StudentRow extends Component {
  static isVstProduct = product => product?.platform === PLATFORMS.VST;

  static isEltcoreProduct = product => product?.platform === PLATFORMS.ELTCORE;

  static handleClickVSTProducts = (activityId, classroomId, learnerId, product) => {
    if (product.vstDomain && product.isbn) {
      const queryParams = {
        domain: product.vstDomain,
        productTitle: product.title,
        contextId: classroomId,
        activityId,
        studentId: learnerId
      };
      const productUrl = getProductUrl(
        `/product/${HubLayoutConstants.DOWNLOAD_TYPES.VST}/${product.isbn}`,
        queryParams
      );
      openProductPage(productUrl);
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      isTooltipOpen: false,
      key: null
    };
  }

  render() {
    const {
      recordWithTotals: {
        studentName,
        studentLink,
        lastAccessed,
        lastOpened,
        isActivityLevel,
        showBlankValue,
        levels = [],
        totalLevelsCompleted,
        totalNumberOfActivities: overallTotalNumberOfActivities,
        totalLevelsScore,
        totalLevelsScoreAvailable,
        sumTotalNumberOfAttempts,
        id,
        numberOfCompletedActivities
      },
      usePercentages = false,
      rangeValue,
      useFilterByScore,
      params,
      products,
      shouldDisableAnswerView,
      tooltipText,
      attemptFilter,
      productType,
      useCompletedOnly,
      handleClickEltcoreReview
    } = this.props;

    const { totalAttemptsFormatted, totalLevelsFormatted } = getFormattedAttemptsLevels(
      {
        isActivityLevel,
        levels,
        totalLevelsCompleted,
        totalLevelsScore,
        totalLevelsScoreAvailable,
        sumTotalNumberOfAttempts,
        totalNumberOfActivities: overallTotalNumberOfActivities,
        numberOfCompletedActivities
      },
      usePercentages,
      attemptFilter
    );

    const { isTooltipOpen, key } = this.state; // isProdAssesment
    const isAssessmentProduct = productType === RESOURCE_TYPE.ASSESSMENT.toLowerCase();
    const gradebookProduct = products?.[params.itemId];
    let productForTeacher = {};
    const isVstProduct = StudentRow.isVstProduct(gradebookProduct);
    const isEltcoreProduct = StudentRow.isEltcoreProduct(gradebookProduct);

    if (gradebookProduct && isVstProduct && gradebookProduct?.title) {
      const productTitle = gradebookProduct.title;

      productForTeacher = Object.values(products).filter(
        product =>
          product?.title === productTitle && product?.target_usertype === HubLayoutConstants.TARGET_USERTYPE.TEACHER
      )[0];
    }

    return (
      <GradebookTable.Row>
        <GradebookTable.Header stickyColumn textAlign="left">
          <TextLink
            to={{
              pathname: studentLink,
              search: window.location.search,
              hash: window.location.hash
            }}
            component={RouterLink}
            dataAttributes={{ tooltip: studentName }}
          >
            {studentName.length > 18 ? `${studentName.substring(0, 15)}...` : studentName}
          </TextLink>
        </GradebookTable.Header>
        <GradebookTable.Cell>{getLastAccessedOrOpened(showBlankValue, lastOpened, lastAccessed)}</GradebookTable.Cell>
        <GradebookTable.Cell shaded>{showBlankValue ? '-' : totalAttemptsFormatted}</GradebookTable.Cell>
        <GradebookTable.Cell>{showBlankValue ? '-' : totalLevelsFormatted}</GradebookTable.Cell>
        {levels.map(
          (
            {
              levelName, // Unit level
              hierarchy,
              levelScoreAchieved,
              levelScoreAvailable,
              submittedNumberOfActivities,
              totalNumberOfActivities,
              activityName, // Activity level
              totalNumberOfAttempts,
              totalScoreAchieved,
              totalScoreAvailable,
              allActivityLevelScoreAvailable,
              allActivityTotalScoreAvailable,
              uId,
              attemptId,
              pendingMark,
              locked,
              activityId: activityHashedId,
              eltAuthorId
            },
            index
          ) => {
            const {
              levelCompletionSlashAttempts,
              levelSlashActivityScore,
              isHighlightScore
            } = getLevelCompletionActivities(
              {
                activityName,
                levelScoreAchieved,
                levelScoreAvailable,
                allActivityLevelScoreAvailable,
                allActivityTotalScoreAvailable,
                submittedNumberOfActivities,
                totalNumberOfActivities,
                totalNumberOfAttempts,
                totalScoreAchieved,
                totalScoreAvailable
              },
              isActivityLevel,
              usePercentages,
              rangeValue,
              useFilterByScore,
              attemptFilter,
              useCompletedOnly
            );
            let studentAnswerViewLink = '';
            if (isActivityLevel && levelSlashActivityScore !== '-') {
              const { bid, activityId } = getUidDetails(window.location.hash, uId);
              const parentUId = getParentUId(hierarchy);
              studentAnswerViewLink = `orgId/${params.orgId}/class/${params.classroomId}/${params.itemId}/${id}/${bid}/${uId}/${activityId}/${parentUId}?page=${RETURN_PAGE_TARGET.CLASS_PROGRESS}`;
            }

            let vstActivityId = '';
            if (isVstProduct && uId) {
              vstActivityId = uId.split(':')[1];
            }

            const getLinkVSTProducts = () => {
              StudentRow.handleClickVSTProducts(vstActivityId, params.classroomId, id, productForTeacher);
            };

            const renderReviewButton = () => {
              if (isEltcoreProduct) {
                const { orgId } = params;
                const contextCode = gradebookProduct?.contentCode;

                const reviewParams = {
                  activityId: activityHashedId,
                  attemptId,
                  contextCode,
                  eltAuthorId,
                  targetUserId: id,
                  targetUserOrgId: orgId
                };

                return (
                  <Button
                    disabled={!attemptId && featureIsEnabled('gradebook-first-and-last-answer')}
                    iconOnly
                    onClick={() => handleClickEltcoreReview(reviewParams)}
                    type={buttonTypes.ROUNDED_SMALL}
                  />
                );
              }

              return (
                <Button
                  disabled={!attemptId && featureIsEnabled('gradebook-first-and-last-answer')}
                  iconOnly
                  to={`/studentAnswerView/${studentAnswerViewLink}&attemptId=${attemptId}`}
                  type={buttonTypes.ROUNDED_SMALL}
                />
              );
            };

            return [
              <GradebookTable.Cell key={`completed-${levelName || activityName}`} shaded locked={locked}>
                {showBlankValue ? '-' : levelCompletionSlashAttempts}
              </GradebookTable.Cell>,
              <GradebookTable.Cell
                key={`score-${levelName || activityName}`}
                isHighlightScore={showBlankValue ? '-' && false : isHighlightScore}
                locked={locked}
              >
                {!isActivityLevel && levelSlashActivityScore !== '-' && isVstProduct && pendingMark && (
                  <PencilReviewButton />
                )}
                {showBlankValue ? '-' : !(isActivityLevel && pendingMark) && levelSlashActivityScore}
                {isActivityLevel && levelSlashActivityScore !== '-' && isVstProduct ? (
                  <div className={styles.roundIcon}>
                    {pendingMark ? (
                      <PencilReviewButton buttonActive getActivityLink={getLinkVSTProducts} />
                    ) : (
                      <div>
                        {productForTeacher?.vstDomain && !shouldDisableAnswerView ? (
                          <Button
                            iconOnly
                            type={buttonTypes.ROUNDED_SMALL}
                            onClick={
                              () =>
                                StudentRow.handleClickVSTProducts(
                                  vstActivityId,
                                  params.classroomId,
                                  id,
                                  productForTeacher
                                )
                              // eslint-disable-next-line prettier/prettier
                            }
                          />
                        ) : (
                          <div
                            role="button"
                            aria-hidden="true"
                            className={styles.tooltip}
                            onClick={() => {
                              this.setState({ isTooltipOpen: true, key: `${activityName}-${id}` });
                            }}
                            onBlur={() => this.setState({ isTooltipOpen: false, key: null })}
                            onMouseOut={() => {
                              this.setState({ isTooltipOpen: false, key: null });
                            }}
                          >
                            <Button iconOnly disabled type={buttonTypes.ROUNDED_SMALL} />
                            {isTooltipOpen && key === `${activityName}-${id}` && (
                              <div
                                className={
                                  index === levels.length - 1 ? styles.tooltiptextLastRecord : styles.tooltiptextNormal
                                }
                              >
                                <span>{tooltipText}</span>
                                <i />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  studentAnswerViewLink &&
                  !isAssessmentProduct &&
                  featureIsEnabled('olb-gradebook-student-answer-view') && (
                    <div className={styles.roundIcon}>
                      {shouldDisableAnswerView ? (
                        <div
                          role="button"
                          aria-hidden="true"
                          className={styles.tooltip}
                          onClick={() => {
                            this.setState({ isTooltipOpen: true, key: `score-${levelName || activityName}` });
                          }}
                          onBlur={() => this.setState({ isTooltipOpen: false, key: null })}
                          onMouseOut={() => {
                            this.setState({ isTooltipOpen: false, key: null });
                          }}
                        >
                          <Button iconOnly disabled type={buttonTypes.ROUNDED_SMALL} />
                          {isTooltipOpen && key === `score-${levelName || activityName}` && (
                            <div
                              className={
                                index === levels.length - 1 ? styles.tooltiptextLastRecord : styles.tooltiptextNormal
                              }
                            >
                              <span>{tooltipText}</span>
                              <i />
                            </div>
                          )}
                        </div>
                      ) : (
                        renderReviewButton()
                      )}
                    </div>
                  )
                )}
              </GradebookTable.Cell>
            ];
          }
        )}
      </GradebookTable.Row>
    );
  }
}

StudentRow.propTypes = {
  recordWithTotals: PropTypes.object,
  usePercentages: PropTypes.bool,
  useCompletedOnly: PropTypes.bool,
  rangeValue: PropTypes.number,
  useFilterByScore: PropTypes.bool,
  params: PropTypes.object,
  shouldDisableAnswerView: PropTypes.bool,
  tooltipText: PropTypes.string,
  products: PropTypes.object,
  attemptFilter: PropTypes.string,
  productType: PropTypes.string,
  handleClickEltcoreReview: PropTypes.func
};

export default StudentRow;
