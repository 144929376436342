import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import withLocalizedContent from '../../language/withLocalizedContent';
import TextInputWithButton from '../TextInputWithButton/TextInputWithButton';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './LtiLicenceCreditsForm.scss';
import LtiLicenceProductSearchItem from './LtiLicenceProductSearchItem';
import LtiLicenceProductListItem from './LtiLicenceProductListItem';
import { clearFoundProduct, findProductRequest } from '../../redux/actions/findProductActions';
import { getExistingProductsRequest } from '../../redux/actions/getProductsActions';
import { addProductCreditsRequest, getProductCreditsRequest } from '../../redux/actions/productCreditsActions';

function LtiLicenceCreditsForm({ localizedContent: { ltiLicenceCreditsForm: localizedContent }, orgId, orgName }) {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [addedProducts, setAddedProducts] = useState([]);
  const [isbnFormatError, setIsbnFormatError] = useState(false);

  const existingProducts = useSelector(state => state.existingProducts.success);
  const foundProducts = useSelector(state => state.findProduct.success);
  const foundProductsError = useSelector(state => state.findProduct.error);
  const currentProductCreditData = useSelector(state => state.productCredits.credits);

  useEffect(() => {
    dispatch(getExistingProductsRequest(orgId));
  }, []);

  useEffect(() => {
    setAddedProducts(existingProducts);
  }, [existingProducts]);

  const searchProducts = () => {
    if (searchValue.length < 13) {
      setIsbnFormatError(true);
      return;
    }

    setIsbnFormatError(false);

    dispatch(findProductRequest({ isbn: searchValue }));
  };

  const addProduct = product => {
    const productId = product.productid;
    const exists = addedProducts.find(p => p.productid === productId);

    if (exists) {
      return;
    }

    dispatch(getProductCreditsRequest({ orgId, productId: product.productid }));

    setAddedProducts([...addedProducts, product]);
    setSearchValue('');
    dispatch(clearFoundProduct());
  };

  const onAddCredits = (productId, amount) => {
    if (Number.isNaN(amount)) {
      return;
    }

    dispatch(
      addProductCreditsRequest({
        orgId,
        productId,
        amount
      })
    );
  };

  const escapeCSVValue = value => {
    if (typeof value !== 'string') {
      return value;
    }
    if (value.includes('"')) {
      return `"${value.replace(/"/g, '""')}"`;
    }

    return value;
  };

  const downloadReport = e => {
    e.preventDefault();

    let csvContent = `"Organisation Name","${orgName}","Credits Available","Used Credits"\n`;
    addedProducts.forEach(product => {
      const { title, isbn } = product;

      const availableCredits = currentProductCreditData[product.productid] || product.credits || 0;
      const usedCredits = product.usedCredits || 0;
      csvContent += `"${escapeCSVValue(title)}","${escapeCSVValue(isbn)}","${escapeCSVValue(
        availableCredits
      )}","${escapeCSVValue(usedCredits)}"\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);

      const fileName = `${orgName}_${new Date()
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        })
        .replace(/\//gi, '')}`;

      link.setAttribute('download', `${fileName}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <div className={styles.headerInsert}>
        <button type="button" onClick={e => downloadReport(e)}>
          Download Report
        </button>
      </div>
      <div className={`${styles.heading} ${styles.contentLine}`}>
        <div>{localizedContent.products_label}</div>
        <div>{localizedContent.credits_label}</div>
      </div>
      <div className={styles.contentLine}>
        <div>{localizedContent.add_products_notice}</div>
      </div>
      {addedProducts.length > 0 ? (
        <div className={styles.contentLine}>
          {addedProducts.map((product, index) => (
            <LtiLicenceProductListItem
              key={`added-product-${index}`}
              product={product}
              credits={currentProductCreditData[product.productid] || product.credits || 0}
              onAddCredits={onAddCredits}
            />
          ))}
        </div>
      ) : null}
      <div className={`${styles.contentLine} ${styles.noBorder}`}>
        <TextInputWithButton
          className={(foundProductsError || isbnFormatError) && styles.errorInput}
          id="isbn"
          label={localizedContent.isbn_field_label}
          value={searchValue}
          name="isbn"
          disabled={false}
          onChange={setSearchValue}
          buttonIcon={GLYPHS.ICON_SEARCH}
          buttonAction={searchProducts}
        />
        {(foundProductsError || isbnFormatError) && (
          <>
            <div className={styles.errorGlyph}>
              <SVGIcon glyph={GLYPHS.ICON_CROSS} />
            </div>
            <div className={styles.errorText}>{localizedContent.no_products_found_error}</div>
          </>
        )}
      </div>

      {foundProducts && foundProducts.length > 0 && !foundProductsError && !isbnFormatError ? (
        <div className={styles.contentLine}>
          {(foundProducts || []).map((product, index) => (
            <LtiLicenceProductSearchItem
              key={`found-product-${index}`}
              product={product}
              onAdd={() => {
                addProduct(product);
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

LtiLicenceCreditsForm.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string
};

export default compose(withLocalizedContent('ltiLicenceCreditsForm'))(LtiLicenceCreditsForm);
