// eslint-disable-next-line react/jsx-curly-newline
import PropTypes from 'prop-types';
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setGenericFieldValue,
  setGenericFieldValidation,
  setUsernameAvailability
} from '../../../../../../../redux/reducers/enrolUser.reducer.js';

// Utils
import withLocalizedContent from '../../../../../../../language/withLocalizedContent.js';

// Components
import Validation from '../../../../../../../components/Validation/Validation';
import { validationStatusCodes } from '../../../../../../../components/ValidationStatus/ValidationStatusCodes';
import TextInput from '../../../../../../../components/TextInput/TextInput';
import styles from './UsersTable.scss';

const getErrorType = (type, key, content) =>
  ({
    duplicated: content.duplicate_userName,
    badEncoding: `${key} ${content.column_invalid_character}`,
    defaultValidation: `${key} ${content.column_is_invalid}`
  }[type]);

const formatHumanReadableColumn = column => column.replace(/([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, '$1$3 $2$4');

function UserTableRow({
  entry,
  expectedColumns,
  updateEntry,
  validateEntry,
  validateUsername,
  validationRules,
  content,
  orgId
}) {
  return (
    <tr className={styles.tr}>
      <td className={styles.td}>{entry.temp_id}</td>
      {expectedColumns.map((column, key) => {
        const entryKey = column.charAt(0).toLowerCase() + column.slice(1);
        const validationPattern = validationRules[key];
        const [errorType = null, errorState = null] =
          Object.entries(entry.validationErrors[entryKey] || {}).find(([, value]) => value === true) || [];
        const humanReadableColumn = formatHumanReadableColumn(column);
        const errorMessage = getErrorType(errorType, humanReadableColumn, content);
        const isUserNameChecking =
          entryKey === 'userName' && entry?.usernameChecking ? validationStatusCodes.WAITING_STATUS : null;
        const isUsernameColumn = entryKey === 'userName';
        const fieldId = `${entryKey}-${entry.temp_id}`;

        return (
          <td key={key} className={styles.td} valign="top">
            <Validation
              isError={errorState}
              message={errorMessage}
              noIcon
              isWaiting={isUsernameColumn && entry?.usernameChecking}
              forId={fieldId}
            >
              <TextInput
                id={fieldId}
                label={column}
                value={entry[entryKey]}
                readOnly={false}
                onChange={value => updateEntry(entry.temp_id, entryKey, value)}
                onBlur={() =>
                  isUsernameColumn
                    ? validateUsername(entry.temp_id, orgId, entryKey, entry[entryKey], validationPattern)
                    : validateEntry(entry.temp_id, entryKey, entry[entryKey], validationPattern)}
                labelHidden
                validationStatus={isUserNameChecking}
              />
            </Validation>
          </td>
        );
      })}
    </tr>
  );
}

UserTableRow.propTypes = {
  entry: PropTypes.object,
  expectedColumns: PropTypes.array,
  updateEntry: PropTypes.func.isRequired,
  validateEntry: PropTypes.func.isRequired,
  validateUsername: PropTypes.func.isRequired,
  validationRules: PropTypes.array,
  content: PropTypes.object,
  orgId: PropTypes.string
};

function UsersTable({
  entries,
  localizedContent: { importUsersTableComponent: content },
  updateEntry,
  validateEntry,
  validateUsername,
  expectedColumns,
  optionalColumns,
  validationRules,
  options
}) {
  const { orgId } = options;

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>#</th>
          {expectedColumns.map((column, key) => {
            const required = !optionalColumns.includes(column);
            const humanReadableColumn = formatHumanReadableColumn(column);
            return (
              <th
                key={key}
                className={styles.th}
                data-tooltip={required ? `${humanReadableColumn} ${content.column_is_required}` : null}
              >
                {humanReadableColumn}
                {required ? <span className={styles.required}>*</span> : ''}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {entries.map(entry => (
          <UserTableRow
            key={entry.temp_id}
            entry={entry}
            updateEntry={updateEntry}
            validateEntry={validateEntry}
            validateUsername={validateUsername}
            expectedColumns={expectedColumns}
            validationRules={validationRules}
            content={content}
            orgId={orgId}
          />
        ))}
      </tbody>
    </table>
  );
}

UsersTable.propTypes = {
  localizedContent: PropTypes.object,
  entries: PropTypes.array,
  updateEntry: PropTypes.func.isRequired,
  validateEntry: PropTypes.func.isRequired,
  validateUsername: PropTypes.func.isRequired,
  validationRules: PropTypes.array,
  expectedColumns: PropTypes.array,
  optionalColumns: PropTypes.array,
  options: PropTypes.object
};

export default compose(
  withLocalizedContent('importUsersTableComponent'),
  connect(() => ({}), {
    updateEntry: setGenericFieldValue,
    validateEntry: setGenericFieldValidation,
    validateUsername: setUsernameAvailability
  })
)(UsersTable);
