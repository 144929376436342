import * as t from '../actionTypes';
import WebStorage from '../../utils/storage/WebStorage';

const webStorage = new WebStorage('orb-class-report');

const orgId = webStorage.get()?.orgId;
const classroomId = webStorage.get()?.[orgId]?.classroomId;

const initialState = {
  errors: {},
  loading: true,
  success: false,
  failure: false,
  hierarchy: null,
  selectedProduct: null,
  products: {},
  group: {},
  learner: {},
  errorLoading: null,
  data: {},
  dials: {},
  studentData: {},
  classAverages: {},
  productType: '',
  studentsInClass: 0,
  orgId,
  classroomId,
  studentId: webStorage.get()?.[orgId]?.[classroomId]?.studentId
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GRADEBOOK_ASSIGN_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload || 'Product' };
    case t.GRADEBOOK_CLASS_DIAL_REPORT_FAILURE:
      return {
        ...state,
        dials: {
          ...state.dials,
          [action.payload.classId]: {
            ...action.payload
          }
        },
        errors: {
          ...state.errors,
          assignSchoolReport: true
        }
      };
    case t.GRADEBOOK_CLASS_DIAL_REPORT_SUCCESS:
      return {
        ...state,
        dials: {
          ...state.dials,
          [action.payload.classId]: {
            ...(action.payload.data || []).reduce((carry, target) => {
              const attrName = Object.keys(target)[0];
              return {
                ...carry,
                [attrName]: target[attrName].percentage || 0
              };
            }, {})
          }
        }
      };

    case t.GRADEBOOK_CLASS_DIAL_REPORT_REQUEST:
      return { ...state, loading: true };

    case t.GRADEBOOK_CLASS_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        failure: false
      };
    case t.GRADEBOOK_CLASS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        [action.payload.page]: {},
        errors: {
          ...state.errors,
          ...action.payload
        }
      };
    case t.GRADEBOOK_CLASS_REPORT_SUCCESS: {
      const referenceLearner = Object.values(action.payload.data)[0];
      let hierarchy = state.hierarchy;

      if (referenceLearner && (referenceLearner?.levelAggregation?.length || referenceLearner?.activityScore?.length)) {
        hierarchy = (referenceLearner.levelAggregation || referenceLearner.activityScore)[0].hierarchy;
      }

      return {
        ...state,
        loading: false,
        success: true,
        hierarchy,
        [action.payload.page]: action.payload.data.products ? action.payload.data.products : action.payload.data,
        studentsInClass: action.payload.data.studentsInClass ? action.payload.data.studentsInClass : 0
      };
    }
    case t.GRADEBOOK_SELECTED_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload
      };
    case t.GRADEBOOK_SET_REPORTING:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
