import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import styles from './EltBuilder.scss';
import Button, { buttonTypes } from '../../../../components/Button/Button';

function EltBuilder({ localizedContent: { eltBook: content } }) {
  return (
    <PageWrapper>
      <Helmet title="EltBuilder" />
      <h1>{content.title}</h1>
      <span className={styles.subheading}>{content.subtitle}</span>
      <div className={styles.linkContainer}>
        <Button
          to="/eltcore-builder"
          type={buttonTypes.SECONDARY}
          text={content.elt_core_product}
          fullWidth
          customClassName={styles.button}
        />
        <Button
          to="/eltbook-builder"
          type={buttonTypes.SECONDARY}
          text={content.elt_book_product}
          fullWidth
          customClassName={styles.button}
        />
      </div>
    </PageWrapper>
  );
}

EltBuilder.propTypes = {
  localizedContent: PropTypes.object
};

export default withLocalizedContent('eltBook')(EltBuilder);
