import { put } from 'redux-saga/effects';
import { loadOfflineUnits } from '../../../../actions/offlineContentPlayer';

function* updateDownloadOfflineUnitStatus({ unitId, isDownloaded }) {
  const storedValue = localStorage.getItem('offline_units');
  const units = storedValue ? JSON.parse(storedValue) : [];

  const currentUnit = units.find(unit => unit.id === unitId);
  currentUnit.isDownloadCompleted = isDownloaded;

  localStorage.setItem('offline_units', JSON.stringify(units));
  yield put(loadOfflineUnits(units));
}

export default updateDownloadOfflineUnitStatus;
