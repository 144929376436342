import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import moment from 'moment';
import colors, { COLOR_FOR_ROLE } from '../../globals/colors';
import userRoles from '../../globals/userRoles';
import withLocalizedContent from '../../language/withLocalizedContent';
import { bgClass, fgClass } from '../../utils/colorClassNames';
import { toInitials } from '../../utils/string';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './UserRepresentation.scss';
import { DATE_CONSTANTS, licenceExpiryDates } from '../../globals/hubConstants';
import isInThePast from '../../utils/isInThePast';
import { isCesMode, isHubMode } from '../../utils/platform';
import { formatDate } from '../PlacementTestOnBoardingWizard/utils/helpers';

const colorFor = {
  INFO: colors.SUB_TEXT,
  SUCCESS: colors.CORRECT,
  WARNING: colors.WARNING,
  ERROR: colors.ERROR
};
const glyphFor = {
  ERROR: GLYPHS.ICON_ERROR_CIRCLE,
  WARNING: GLYPHS.ICON_WARNING_CIRCLE,
  INFO: GLYPHS.ICON_INFORMATION_CIRCLE,
  WAITING: GLYPHS.ICON_LOADING,
  SUCCESS: GLYPHS.ICON_CHECK_CIRCLE
};

const getStyleTextLicence = expirationDateLicence => {
  if (expirationDateLicence === DATE_CONSTANTS.NO_DATE || isInThePast(expirationDateLicence?.expiresOn)) {
    return styles.textNoLicence;
  }

  const isLicenceCloseToExpiry =
    moment(expirationDateLicence.expiresOn).diff(moment(), 'days', true) <
    APP_CONSTANTS.SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS;

  if (isLicenceCloseToExpiry) {
    return styles.textLicenceCloseToExpiry;
  }

  return styles.textHasLicence;
};

const getIconLicence = expirationDateLicence => {
  if (expirationDateLicence === DATE_CONSTANTS.NO_DATE || isInThePast(expirationDateLicence?.expiresOn)) {
    return glyphFor.ERROR;
  }

  const isLicenceCloseToExpiry =
    moment(expirationDateLicence.expiresOn).diff(moment(), 'days', true) <
    APP_CONSTANTS.SHOW_WARNING_LICENCE_EXPIRING_PERIOD_DAYS;

  if (isLicenceCloseToExpiry) {
    return glyphFor.WARNING;
  }

  return glyphFor.SUCCESS;
};

const getDateToShow = (hubContent, expirationTime) => {
  if (expirationTime === licenceExpiryDates.NEVER) {
    return hubContent.unlimited_licence;
  }

  return moment(expirationTime).format('D MMMM YYYY');
};

function UserRepresentation({
  firstName,
  lastName,
  identifier,
  role = userRoles.LEARNER,
  yearGroup = null,
  locked = false,
  processing = false,
  archived = false,
  deleted = false,
  onLabelClick,
  statusText,
  statusType,
  licenceStatusItem = null,
  localizedContent: { userRepresentation: content, hubGlossary: hubContent, placementTests: placementTestsContent },
  expirationDateLicence = '',
  testStartedAt = null
}) {
  const fullName = firstName || lastName ? `${firstName} ${lastName}` : '[NO NAME]';
  return (
    <article className={styles.main} itemScope itemType="http://schema.org/Person">
      <div
        className={classnames(
          styles.avatar,
          {
            [styles.cesAvatar]: isCesMode(),
            [bgClass(COLOR_FOR_ROLE[role])]: !isCesMode()
          },
          {
            [styles.avatarProcessing]: processing,
            [styles.avatarArchived]: archived,
            [styles.avatarDeleted]: deleted
          }
        )}
      >
        {deleted ? null : toInitials(firstName, lastName)}
        {locked ? <SVGIcon className={styles.lockedIcon} glyph={GLYPHS.ICON_LOCK} fill={COLOR_FOR_ROLE[role]} /> : null}
      </div>
      <div>
        {deleted ? (
          <span className={styles.deletedPlaceholder}>{content.deleted_user_placeholder}</span>
        ) : (
          <div className={styles.staffViewAlign}>
            <div className={locked ? styles.textRed : null}>
              <span className={styles.name}>
                {onLabelClick ? (
                  <button className={styles.nameLink} type="button" onClick={onLabelClick} itemProp="name">
                    {fullName}
                  </button>
                ) : (
                  <span itemProp="name">{fullName}</span>
                )}
                {locked ? <span className={styles.lockedBadge}>Locked</span> : null}
              </span>
            </div>
            {yearGroup ? (
              <span className={styles.badge}>{yearGroup}</span>
            ) : (
              <span className={styles.identifier}>{identifier}</span>
            )}
            {licenceStatusItem != null && (
              <div className={styles.licenceMessage}>
                <div className={styles.svgSpace}>
                  <SVGIcon
                    className={styles.statusIndication}
                    glyph={licenceStatusItem ? glyphFor.ERROR : glyphFor.SUCCESS}
                  />
                </div>
                <span
                  className={classnames(
                    styles.textWarning,
                    licenceStatusItem ? styles.textNoLicence : styles.textHasLicence
                  )}
                >
                  {licenceStatusItem ? hubContent.class_classwork_filter_no_licence : hubContent.has_a_licence}
                </span>
              </div>
            )}

            {testStartedAt != null && (
              <div className={styles.licenceMessage}>
                <div className={styles.svgSpace}>
                  <SVGIcon className={styles.statusIndication} glyph={glyphFor.SUCCESS} />
                </div>
                <span className={classnames(styles.textWarning, styles.textHasLicence)}>
                  {placementTestsContent.started_test_on} <b>{formatDate(testStartedAt, false)}</b>
                </span>
              </div>
            )}

            {isHubMode() && expirationDateLicence !== '' && (
              <div className={styles.licenceMessage}>
                <div className={styles.svgSpace}>
                  <SVGIcon className={styles.statusIndication} glyph={getIconLicence(expirationDateLicence)} />
                </div>
                <span className={classnames(styles.textWarning, getStyleTextLicence(expirationDateLicence))}>
                  {expirationDateLicence !== DATE_CONSTANTS.NO_DATE && !isInThePast(expirationDateLicence?.expiresOn)
                    ? `${hubContent.licence_expiry_date} ${getDateToShow(hubContent, expirationDateLicence?.expiresOn)}`
                    : hubContent.class_classwork_filter_no_licence}
                </span>
              </div>
            )}

            {statusText && (
              <div>
                <span className={classnames(styles.statusText, fgClass(colorFor[statusType]))}>
                  {statusType && (
                    <SVGIcon className={styles.statusIndication} glyph={glyphFor[statusType] || glyphFor.NOTICE} />
                  )}
                  {statusText}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </article>
  );
}

UserRepresentation.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  identifier: PropTypes.string,
  role: PropTypes.string,
  yearGroup: PropTypes.string,
  locked: PropTypes.bool,
  processing: PropTypes.bool,
  archived: PropTypes.bool,
  deleted: PropTypes.bool,
  onLabelClick: PropTypes.func,
  statusText: PropTypes.string,
  statusType: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  licenceStatusItem: PropTypes.bool,
  expirationDateLicence: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  testStartedAt: PropTypes.string
};

export default withLocalizedContent('userRepresentation', 'hubGlossary', 'placementTests')(UserRepresentation);
