import { parse as parseQueryString } from 'query-string';

import { isPublicPath } from '../../../../../../globals/authSettings';
import session from '../../../../../../utils/session';
import getLinkAccountsStatus from '../../../auth/getLinkAccountsStatus.saga';
import redirectToIdp from '../../../auth/getCredentials/redirectToIdP.saga';
import { completeLogout } from '../../../../appFunctions/authorisedFunctions/auth/handleLogout.saga';

import processAuthUniversalAuth0, { startAuth0Interval, checkCachedTokenAuth0 } from './universal/auth0.saga';
// TODO: fix dependency cycle
// eslint-disable-next-line import/no-cycle
import processAuthUniversalShibboleth, { startShibbolethInterval } from './universal/shibboleth.saga';
import { getCurrentPlatform } from '../../../../../../utils/platform';
import { NO_AUTH_PLATFORMS } from '../../../../../../globals/appConstants';

/**
 * Process the login response or load the stored credentials.
 */
export default function* processAuthUniversal() {
  console.groupCollapsed('Universal');

  const result = {};

  const {
    code,
    jwt,
    state: offlineAppAuth0State,
    offlineAppCustomFlow,
    'impersonation-token': impersonationTokenFromQueryString,
    activityId,
    location,
    node
  } = parseQueryString(window.location.search);

  const processLoginResponse = !!code || !!jwt;

  if (offlineAppAuth0State && offlineAppCustomFlow) {
    sessionStorage.setItem('offlineAppInitialState', offlineAppAuth0State);
  }

  const masqueradeUserId = localStorage.getItem('masquerade-user-id');
  const sessionStoredImpersonationToken = localStorage.getItem('impersonation-token');
  const impersonationToken = impersonationTokenFromQueryString || sessionStoredImpersonationToken;

  // if we have one in qsp, we should overwite the old one in session storage
  if (impersonationTokenFromQueryString) {
    localStorage.setItem('impersonation-token', impersonationTokenFromQueryString);
  }

  const ltiUser = localStorage.getItem('lti-token');

  if (masqueradeUserId || ltiUser || impersonationToken) {
    session.set({
      oidc: {
        idToken: 'oidc-inactive',
        accessToken: 'oidc-inactive',
        refreshToken: 'oidc-inactive',
        expiresIn: 99999,
        sessionClock: 0
      }
    });
    return result;
  }

  // Process login response
  if (processLoginResponse) {
    // Check if the secondary account (to be linked) creds exist.
    // This is needed for Safari ("non-standalone" mode), where the Auth0 popup login
    // doesn't work because of the Intelligent Tracking Prevention (ITP) mechanism.
    const linkAccountsStatus = yield getLinkAccountsStatus(!!code);

    if (linkAccountsStatus !== null) {
      // the user should be redirected to the link accounts page already
      return false;
    }

    console.log('Start processing login response.');

    session.clear();

    try {
      if (code) {
        // Auth0
        yield processAuthUniversalAuth0();
      } else if (jwt) {
        yield processAuthUniversalShibboleth();
      }
    } catch (error) {
      // Session should not be set if we get to this point!
      console.log('Error: ', error);
      return result;
    }
  } else {
    // Check if cached token is valid
    yield checkCachedTokenAuth0();
  }

  try {
    // Try to keep the session alive.
    const sessionInfo = session.get();
    if (sessionInfo.oidc.idToken) {
      yield startAuth0Interval();
    } else if (sessionInfo.saml.sessionToken) {
      startShibbolethInterval();
    }
  } catch (error) {
    console.info('Renew error: ', error);
    yield completeLogout();
    return result;
  }

  // Clear session when token prior to refactor (PR 7705) is already stored
  // Code can be deleted after some time. E.G. Jan 2022
  const sessionInfo = session.get();
  if (!(sessionInfo.oidc.idToken || sessionInfo.saml.sessionToken)) {
    session.clear();
  }

  if (!session.exists()) {
    const options = {};

    if (isPublicPath(window.location.pathname)) {
      options.withReturnTo = false;
      options.navigateTo = window.location.href;

      if (!NO_AUTH_PLATFORMS.includes(getCurrentPlatform()))
        try {
          yield processAuthUniversalAuth0(false, options);
        } catch (e) {
          //
        }
    } else {
      // Call logout if there is no valid session and the resource is not public.
      console.log('No valid session found, redirecting to login!');

      const { navigateTo, target_url: targetUrl, account_linking: accountLinking } = parseQueryString(
        window.location.search
      );

      if (navigateTo) {
        options.withReturnTo = false;
        options.navigateTo = navigateTo;

        if (accountLinking) {
          options.accountLinking = true;
        }
      } else if (targetUrl) {
        options.withReturnTo = false;
        options.redirect_uri = window.location.href;
      }

      // EPS-15648: If activityId, node or location is in query string, keep query string after login redirection
      if (activityId || location || node) {
        options.withReturnTo = false;
        options.redirect_uri = window.location.href;
      }

      yield redirectToIdp(null, options);
    }
  }

  console.groupEnd();

  return result;
}
