import { takeLatest } from 'redux-saga/effects';

import * as t from '../../../../actionTypes';
import * as enrolUserTypes from '../../../../reducers/enrolUser.reducer';
import checkImportUsers from './checkImportUsers';
import destroyImportUsers from './destroyImportUsers';
import parseImportUsersSource from './parseImportUsersSource';
import updateImportUsers from './updateImportUsers';
import uploadImportUsers from './uploadImportUsers';
import validateImportUsers from './validateImportUsers';
import bulkUsersUploadRequest from '../organisation/bulkEnrolUser/bulkUsersUploadRequest.saga';
import bulkUsersUploadByUserTypeRequest from '../organisation/bulkEnrolUser/bulkUploadByUserTypeRequest.saga';
import bulkReviewableUploadRequest from '../organisation/bulkEnrolUser/bulkReviewableUploadRequest.saga';

function* root() {
  yield takeLatest(t.PARSE_IMPORT_USERS_SOURCE_REQUEST, ({ payload: source }) => parseImportUsersSource(source));
  yield takeLatest(t.UPDATE_IMPORT_USERS_REQUEST, ({ payload: updates }) => updateImportUsers(updates));
  yield takeLatest(t.DESTROY_IMPORT_USERS_REQUEST, ({ payload: id }) => destroyImportUsers(id));
  yield takeLatest(t.CHECK_IMPORT_USERS_REQUEST, checkImportUsers);
  yield takeLatest(t.UPLOAD_IMPORT_USERS_REQUEST, uploadImportUsers);
  yield takeLatest(t.VALIDATE_IMPORT_USERS_REQUEST, ({ payload: { id, input } }) => validateImportUsers(id, input));
  yield takeLatest(enrolUserTypes.CSV_BULK_SUBMIT_FORM, bulkUsersUploadRequest);
  yield takeLatest(enrolUserTypes.BULK_BY_USER_TYPE_SUBMIT_FORM, bulkUsersUploadByUserTypeRequest);
  yield takeLatest(enrolUserTypes.CSV_BULK_REVIEWABLE_SUBMIT_FORM, payload => bulkReviewableUploadRequest(payload));
}

export default root;
