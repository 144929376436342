import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import {
  getJwtAndDeepLinkSuccess,
  getJwtAndDeepLinkFailure
} from '../../../../actions/getJwtAndDeeplinkReturnUrlActions.js';
import fetchJwtAndDeeplinkReturnUrlApi from '../../../apiCalls/ngi/fetchJwtAndDeeplinkReturnUrlApi.js';

function* fetchJwtAndDeeplinkReturnUrl(action) {
  const response = yield call(fetchJwtAndDeeplinkReturnUrlApi, action.payload);
  if (response.jwt && response.deepLinkReturnUrl) {
    yield put(getJwtAndDeepLinkSuccess(response));
    action.callBack({ ...response, items: action.payload.items });
  } else {
    yield put(getJwtAndDeepLinkFailure('Failed to fetch jwt and deeplink return url'));
  }
}

function* fetchJwtAndDeeplinkReturnUrlSaga() {
  yield takeLatest(t.NGI_FETCH_JWT_AND_DEEPLINK_REQUEST, fetchJwtAndDeeplinkReturnUrl);
}

export default fetchJwtAndDeeplinkReturnUrlSaga;
