import userRoles from '../globals/userRoles';

/**
 * Returns an array of roles that the current authenticated user is able to assign to a self-managed user
 *
 * @param {Object}
 *
 * @return {Array}
 */
function getAssignableRoles({ authUserRole, editUserRole, isPrimarySchool, isPlacementCentre = false }) {
  const isAuthUserOrgAdmin = authUserRole === userRoles.ORG_ADMIN;
  const isAuthUserOupSupport = authUserRole === userRoles.OUP_SUPPORT;
  const isAuthUserOupAdmin = authUserRole === userRoles.OUP_ADMIN;

  let roles;
  switch (true) {
    case isAuthUserOupAdmin:
      roles = [
        userRoles.OUP_ADMIN,
        userRoles.OUP_SUPPORT,
        userRoles.OUP_CONTENT,
        userRoles.ORG_ADMIN,
        userRoles.TEACHER_ADMIN,
        userRoles.TEACHER
      ].concat(isPrimarySchool ? [] : [userRoles.LEARNER]);
      break;
    case isAuthUserOupSupport && !(editUserRole === userRoles.OUP_ADMIN):
      roles = [userRoles.OUP_SUPPORT, userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN, userRoles.TEACHER].concat(
        isPrimarySchool ? [] : [userRoles.LEARNER]
      );
      break;
    case isAuthUserOrgAdmin && ![userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT].includes(editUserRole):
      roles = [userRoles.ORG_ADMIN, userRoles.TEACHER_ADMIN].concat(
        isPlacementCentre ? [] : [userRoles.TEACHER],
        isPrimarySchool ? [] : [userRoles.LEARNER]
      );
      break;
    default:
      roles = [editUserRole];
  }
  return roles;
}

export default getAssignableRoles;
