import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PanelSearchControl from '../components/PanelSearchControl/PanelSearchControl';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import PanelNavigationLink from '../components/PanelNavigationLink/PanelNavigationLink';
import USER_ROLES, { getRoleLabelPlural } from '../globals/userRoles';
import UserSelectionResultGroup from '../components/UserSelectionResultGroup/UserSelectionResultGroup';
import colors from '../globals/colors';
import ToggleWithNumberInput from '../components/ToggleWithNumberInput/ToggleWithNumberInput';
import PopoutNavFooter from '../components/PopoutNavFooter/PopoutNavFooter';
import Validation from '../components/Validation/Validation';
import styles from './UserSelectionView.scss';
import PanelHeading from '../components/PanelHeading/PanelHeading';
import { SIZES as thumbnailSizes } from '../components/Thumbnail/Thumbnail';
import { GLYPHS } from '../components/SVGIcon/SVGIcon';
import equals from '../utils/array/equals';
import PaginationButtons from '../components/PaginationButtons/PaginationButtons';
import SubSectionSkeletonLoader from '../components/SkeletonLoader/SubSectionSkeletonLoader';
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../globals/hubConstants';
import { isHubMode } from '../utils/platform';
import { featureIsEnabled } from '../globals/envSettings';
import HubEmptyStateRestyle from '../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import HubEmptyState from '../components/HubEmptyState/HubEmptyState';
import SelectedRow from '../components/SelectedRow/SelectedRow';

const ROLE_ORDER = [
  USER_ROLES.MANAGED_USER,
  USER_ROLES.LEARNER,
  USER_ROLES.TEACHER,
  USER_ROLES.TEACHER_ADMIN,
  USER_ROLES.ORG_ADMIN
];

const RESULT_GROUP_IDS = {
  [USER_ROLES.ORG_ADMIN]: 'orgAdminSelectionResults',
  [USER_ROLES.TEACHER_ADMIN]: 'teacherAdminSelectionResults',
  [USER_ROLES.TEACHER]: 'teacherSelectionResults',
  [USER_ROLES.LEARNER]: 'studentSelectionResults'
};

export const CONTEXT_OPTIONS = {
  CREATE_CLASS: 'CLASS_CREATE',
  EDIT_CLASS: 'EDIT_CLASS',
  ADD_STUDENTS: 'ADD_STUDENTS',
  ADD_TEACHERS: 'ADD_TEACHERS'
};

function UserSelectionView({
  localizedContent: content,
  backAction,
  toggleRender,
  closeAction,
  context,
  isPrimarySchool,
  disabledIds,
  externalLimitBaseValue,
  limitToggleValue,
  limitValue,
  nextAction,
  usersByRole,
  selectedIds,
  setLimitAction,
  setLimitToggleAction,
  showLimitControls,
  updateSelectionAction,
  clearSelectionAction,
  setFilterAction,
  setSearchTerm,
  setSortFilter,
  initialSelectedIds,
  sortOptions,
  isLoading = false,
  filterOptions,
  initialSelectedValues,
  classroomName,
  totalResults,
  size,
  page,
  setPageAction,
  showYearGroup = false,
  isOnlineTestStudentsPanel = false,
  filterOptionsSelected,
  withFilters = false,
  isPlacementTest,
  setShowAddStudentsPanel
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filtersReset, setFiltersReset] = useState(false);

  const handleSetFilter = filters => {
    const initialValuesWithoutManagedUsers = initialSelectedValues.filter(item => item !== USER_ROLES.MANAGED_USER);
    const hasNewFilters = !(
      filters.length && filters.every(filterOption => initialValuesWithoutManagedUsers.includes(filterOption))
    );
    setIsFiltering(hasNewFilters);
    setFilterAction(filters);
    setFiltersReset(false);
  };

  const handleSetSearchTerm = searchTerm => {
    setFiltersReset(false);
    setIsSearching(!!searchTerm);
    setSearchTerm(searchTerm);
  };

  const handleSetSortFilter = sortFilter => {
    setFiltersReset(false);
    setSortFilter(sortFilter);
  };

  const mainContent = () => {
    const noUsersFound = !totalResults;
    const hasFiltersApplied = isSearching || isFiltering || withFilters;
    const navigateToOrgStudentsPage = () => {
      backAction();

      const newTab = window.open(
        `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`,
        '_blank'
      );
      if (newTab) newTab.opener = null;
    };
    const cesUrl = '/mySchool/students';

    if (isLoading) {
      return (
        <SubSectionSkeletonLoader
          largeRectWidth="60%"
          smallRectWidth="40%"
          speed={2}
          foregroundColor={colors.COLOR_GREY_DISABLED}
          backgroundColor={colors.COLOR_WHITE}
        />
      );
    }

    if (noUsersFound) {
      let title = content.no_students_in_org_title;
      let subTitle = content.no_students_in_org_subtitle;
      let iconSrc = HubIllustrationConstants.SEARCH;
      let iconAlt = HubIllustrationAltText.SEARCH;
      let emptyStateButtons = {
        showButton: true,
        isPrimaryButton: true,
        text: content.go_to_org_button,
        link: isHubMode() ? '' : cesUrl,
        action: isHubMode() ? navigateToOrgStudentsPage : ''
      };
      if (hasFiltersApplied) {
        title = content.no_results_title;
        subTitle = content.no_results_subtitle;
        iconSrc = HubIllustrationConstants.INFORMATION;
        iconAlt = HubIllustrationAltText.INFORMATION;
        emptyStateButtons = {
          showButton: true,
          isPrimaryButton: true,
          text: content.reset_filters_button,
          icon: GLYPHS.ICON_REFRESH,
          action: () => {
            setSearchTerm('');
            setFilterAction(initialSelectedValues);
            setFiltersReset(true);
          }
        };
      }

      if (isPlacementTest) {
        subTitle = (
          <>
            {content.no_students_in_org_subtitle_opt_no_students} <br />
            {content.no_students_in_org_subtitle_opt_add_or_invite}
          </>
        );
        emptyStateButtons = {
          showButton: true,
          isPrimaryButton: true,
          text: content.add_or_invite_students,
          icon: GLYPHS.ICON_PLUS,
          action: () => {
            setShowAddStudentsPanel(true);
          }
        };
      }

      if (isHubMode()) {
        return (
          <HubEmptyStateRestyle
            iconSrc={iconSrc}
            iconAlt={iconAlt}
            title={title}
            bodyText={subTitle}
            btnFilledBase={emptyStateButtons}
          />
        );
      }

      return (
        <HubEmptyState
          glyphIcon={GLYPHS.ICON_INFORMATION_CIRCLE}
          glyphSize={thumbnailSizes.LARGE}
          title={title}
          subTitle={subTitle}
          buttons={[emptyStateButtons]}
          customClassName="userSelectionEmptyState"
        />
      );
    }

    return ROLE_ORDER.map((role, index) => {
      const groupedUsers = usersByRole[role] || {};
      const hasResults = !!Object.keys(groupedUsers).length;
      const roleLabel = getRoleLabelPlural(role);
      return (
        hasResults && (
          <UserSelectionResultGroup
            key={index}
            id={RESULT_GROUP_IDS[role] || `${role}selectionResults`}
            roleName={role}
            title={!isOnlineTestStudentsPanel ? roleLabel : `${classroomName} ${roleLabel}`}
            items={groupedUsers}
            selectedIds={selectedIds}
            disabledIds={disabledIds}
            updateSelection={updateSelectionAction}
            showYearGroup={showYearGroup}
          />
        )
      );
    });
  };

  return (
    <ScrollContainer
      title={content.panel_title}
      headerContent={
        <div>
          <div className="text-right">
            {closeAction ? (
              <PanelNavigationLink isLhs={false} text={content.close_button} action={closeAction} />
            ) : null}
          </div>

          {!isOnlineTestStudentsPanel && (
            <PanelHeading
              title={content.panel_title}
              subtitle={content.panel_subtitle.replace('{classroomName}', classroomName)}
              customClassName="addStudentsSelectionPanel"
            />
          )}
          {isOnlineTestStudentsPanel && <h2 className={styles.onlineTestHeading}>{content.online_test_title} </h2>}
          <PanelSearchControl
            filterOptions={!isPlacementTest ? filterOptions : []}
            filterOptionsByUserType={filterOptions}
            initialSelectedFilterOptions={initialSelectedValues}
            sortOptions={sortOptions}
            placeholder={
              isPrimarySchool === undefined || isPrimarySchool
                ? content.search_bar_placeholder
                : content.search_bar_placeholder_secondary
            }
            filterOptionsSelected={filterOptionsSelected}
            allowMultipleFilterOptions
            panelType={!isPlacementTest ? HubLayoutConstants.FILTER_PANEL_TYPES.ADD_STUDENTS_CLASS : ''}
            isOnlineTestStudentsPanel={isOnlineTestStudentsPanel}
            filtersReset={filtersReset}
            setFilter={filter => handleSetFilter(filter)}
            setSearchTerm={searchTerm => handleSetSearchTerm(searchTerm)}
            setSortFilter={sortFilter => handleSetSortFilter(sortFilter)}
            setFilterByUserType={filters => handleSetFilter(filters)}
          />
        </div>
      }
      footerContent={
        <div>
          {selectedIds.length ? (
            <SelectedRow
              content={content}
              limitToggleValue={limitToggleValue}
              selectedIds={selectedIds}
              limitValue={limitValue}
              externalLimitBaseValue={externalLimitBaseValue}
              showClearSelection={featureIsEnabled('opt-main-features')}
              clearSelectionHandler={clearSelectionAction}
            />
          ) : null}

          {showLimitControls && toggleRender && isPrimarySchool && (
            <div className={styles.limitToggle}>
              <Validation
                isWarning={limitToggleValue && limitValue < selectedIds.length}
                message={content.student_limit_error_message}
                rightHandInput
                forId="selectionLimit"
                noLabelAboveField
              >
                <ToggleWithNumberInput
                  toggleId="selectionLimitToggle"
                  toggleLabel={content.limit_toggle_label}
                  toggleValue={limitToggleValue}
                  toggleOnChange={toggleValue => setLimitToggleAction(toggleValue, externalLimitBaseValue)}
                  numberInputId="selectionLimit"
                  numberInputLabel={content.limit_label}
                  numberInputValue={limitValue}
                  numberInputOnChange={setLimitAction}
                  numberInputMin={1}
                />
              </Validation>
            </div>
          )}
          <PopoutNavFooter
            backAction={() => backAction(selectedIds, limitToggleValue, limitValue)}
            nextAction={() => nextAction(selectedIds, limitToggleValue, limitValue)}
            nextButtonText={isOnlineTestStudentsPanel ? content.online_test_choose_students : content.next_button}
            nextButtonDisabled={(() => {
              if (context === CONTEXT_OPTIONS.CREATE_CLASS) {
                return false;
              }
              if (context === CONTEXT_OPTIONS.EDIT_CLASS) {
                return equals(selectedIds, initialSelectedIds);
              }
              return !selectedIds.length;
            })()}
          />
        </div>
      }
    >
      <div>
        {mainContent()}
        {!isLoading && totalResults / size > 1 ? (
          <div className="gin2">
            <PaginationButtons
              idPrefix="searchResults"
              value={page}
              numberOfPages={Math.ceil(totalResults / size)}
              onClick={setPageAction}
              aria={{ 'aria-controls': 'searchResults' }}
            />
          </div>
        ) : null}
      </div>
    </ScrollContainer>
  );
}

UserSelectionView.propTypes = {
  context: PropTypes.string,
  isPrimarySchool: PropTypes.bool,
  initialSelectedIds: PropTypes.arrayOf(PropTypes.string),
  externalLimitBaseValue: PropTypes.number,
  disabledIds: PropTypes.arrayOf(PropTypes.string),
  closeAction: PropTypes.func,
  backAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  limitToggleValue: PropTypes.bool,
  limitValue: PropTypes.number,
  usersByRole: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setLimitAction: PropTypes.func,
  setLimitToggleAction: PropTypes.func,
  showLimitControls: PropTypes.bool,
  updateSelectionAction: PropTypes.func.isRequired,
  clearSelectionAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortFilter: PropTypes.func.isRequired,
  sortOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  filterOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  showYearGroup: PropTypes.bool,
  initialSelectedValues: PropTypes.array,
  localizedContent: PropTypes.object.isRequired,
  classroomName: PropTypes.string.isRequired,
  totalResults: PropTypes.number,
  size: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  toggleRender: PropTypes.bool,
  setPageAction: PropTypes.func.isRequired,
  isOnlineTestStudentsPanel: PropTypes.bool,
  filterOptionsSelected: PropTypes.array,
  withFilters: PropTypes.bool,
  isPlacementTest: PropTypes.bool,
  setShowAddStudentsPanel: PropTypes.func
};

export default UserSelectionView;
