import { INCLUDES_LETTERS_REDEEM_CODE_REGEX } from './activationCode/activationCodeUtil';

const INITIALISE_FORM = 'addToLibrary/INITIALISE_FORM';
const SHOW_ADD_TO_LIBRARY_FORM = 'addToLibrary/SHOW_ADD_TO_LIBRARY_FORM';
const SHOW_BULK_FORM = 'addToLibrary/SHOW_BULK_FORM';
export const SHOW_CONFIRMATION = 'addToLibrary/SHOW_CONFIRMATION';
const SET_PRODUCT_DETAILS = 'addToLibrary/SET_PRODUCT_DETAILS';
const SET_PRODUCT_FETCH_ERROR = 'addToLibrary/SET_PRODUCT_FETCH_ERROR';
const SELECT_FILE = 'addToLibrary/SELECT_FILE';
const RESET_FILE = 'addToLibrary/RESET_FILE';

export const SUBMIT_FORM = 'addToLibrary/SUBMIT_FORM';
export const SHOW_SUBMITTING = 'addToLibrary/SHOW_SUBMITTING';
export const SHOW_ERROR_REVIEW = 'addToLibrary/ERROR_REVIEW';
export const BULK_SUBMIT_FORM = 'addToLibrary/BULK_SUBMIT_FORM';
export const BULK_SHOW_CONFIRMATION = 'addToLibrary/BULK_SHOW_CONFIRMATION';

// Reset the form
export const RESET_FORM = 'addToLibrary/RESET_FORM';

const SET_ACCESS_CODE = 'addToLibrary/SET_ACCESS_CODE';
const VALIDATE_ACCESS_CODE = 'addToLibrary/VALIDATE_ACCESS_CODE';

const ACCESS_CODE_REGEX = /^(([a-zA-Z\d]{4}-){2}[a-zA-Z\d]{4}|[a-zA-Z\d]{12})$/;

export const formStates = {
  INPUTTING: 'INPUTTING',
  BULK_INPUTTING: 'BULK_INPUTTING',
  SUBMITTING: 'SUBMITTING',
  BULK_SUBMITTING: 'BULK_SUBMITTING',
  CONFIRMATION: 'CONFIRMATION',
  BULK_CONFIRMATION: 'BULK_CONFIRMATION',
  ERROR_REVIEWING: 'ERROR_REVIEWING'
};

export const initialState = {
  formState: formStates.INPUTTING,
  file: null,
  isFileSelected: false,

  // Access code input and validation values
  accessCodeValue: '',
  accessCodeValueValidationMessage: '',
  accessCodeValueIsValid: false,
  accessCodeValueIsError: false,

  errorOccurred: null,
  errorMessage: null,
  productData: {},
  productDetailsLoading: true
};

export default function addToLibrary(state = initialState, action) {
  switch (action.type) {
    // Initialise action
    case INITIALISE_FORM:
      return {
        ...state
      };
    case SHOW_ADD_TO_LIBRARY_FORM:
      return {
        ...state,
        formState: formStates.INPUTTING
      };
    case SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SET_ACCESS_CODE:
      return {
        ...state,
        accessCodeValue: action.accessCodeValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3'),
        accessCodeValueIsValid: ACCESS_CODE_REGEX.test(action.accessCodeValue),
        accessCodeValueIsError: !INCLUDES_LETTERS_REDEEM_CODE_REGEX.test(action.accessCodeValue)
      };
    case VALIDATE_ACCESS_CODE:
      return {
        ...state,
        accessCodeValueIsValid: ACCESS_CODE_REGEX.test(state.accessCodeValue),
        accessCodeValueIsError: !ACCESS_CODE_REGEX.test(state.accessCodeValue)
      };
    case SHOW_SUBMITTING:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_ERROR_REVIEW:
      return {
        ...state,
        formState: formStates.ERROR_REVIEWING,
        failedEntries: action.failedEntries
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        errorOccurred: action.errorOccurred,
        errorMessage: action.errorMessage
      };
    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        productData: action.products,
        productDetailsLoading: false
      };
    case SET_PRODUCT_FETCH_ERROR:
      return {
        ...state,
        productFetchError: true,
        productDetailsLoading: false
      };

    case SHOW_BULK_FORM:
      return {
        ...state,
        formState: formStates.BULK_INPUTTING
      };
    case SELECT_FILE:
      return {
        ...state,
        file: action.file,
        isFileSelected: action.isFileSelected
      };
    case RESET_FILE:
      return {
        ...state,
        file: null,
        isFileSelected: false
      };
    case BULK_SUBMIT_FORM:
      return {
        ...state,
        formState: formStates.BULK_SUBMITTING
      };
    case BULK_SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.BULK_CONFIRMATION
      };

    // Reset the form
    case RESET_FORM:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

// Initialise action
export const initialiseForm = () => ({
  type: INITIALISE_FORM
});

// Submission and result actions
export const showLibraryForm = () => ({
  type: SHOW_ADD_TO_LIBRARY_FORM
});
export const submitForm = () => ({
  type: SUBMIT_FORM
});

export const validateAccessCode = () => ({
  type: VALIDATE_ACCESS_CODE
});

export const setAccessCode = accessCodeValue => ({
  type: SET_ACCESS_CODE,
  accessCodeValue
});

// errorMessage is actually a errorCode not a typical error message you get.
export const showConfirmation = (errorOccurred, errorMessage) => ({
  type: SHOW_CONFIRMATION,
  errorOccurred,
  errorMessage
});

export const setProductDetails = products => ({
  type: SET_PRODUCT_DETAILS,
  products
});

export const setProductFetchError = () => ({
  type: SET_PRODUCT_FETCH_ERROR
});

export const showErrorReview = failedEntries => ({
  type: SHOW_ERROR_REVIEW,
  failedEntries
});

export const showBulkInput = () => ({
  type: SHOW_BULK_FORM
});

export const bulkSubmitForm = () => ({
  type: BULK_SUBMIT_FORM
});

export const selectFile = selectedFileData => ({
  type: SELECT_FILE,
  file: selectedFileData,
  isFileSelected: true
});
export const resetFile = () => ({
  type: RESET_FILE
});

export const bulkShowConfirmation = () => ({
  type: BULK_SHOW_CONFIRMATION
});

// Reset the form
export const resetForm = () => ({
  type: RESET_FORM
});
