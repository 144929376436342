import signedFetch from './util/signedFetch.js';

export default (orgId, orgType, isPlacementCentre, userTypeToEnrol, body) =>
  signedFetch(
    'bulkUsersEnrolment',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/${encodeURIComponent(orgType)}/users/${encodeURIComponent(
      userTypeToEnrol
    )}/bulk-enrolment/?isPlacementCentre=${encodeURIComponent(isPlacementCentre)}`,
    'POST',
    body
  );
