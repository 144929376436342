import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from '../../../../components/PageWrapper/PageWrapper';
import ContentPreview from '../LorContentPage/ContentPreview';

function PreviewELTBookProduct() {
  return (
    <PageWrapper>
      <Helmet title="Preview ELT Core product" />
      <ContentPreview isEpsContentArea isEltbookPreview />
    </PageWrapper>
  );
}

export default PreviewELTBookProduct;
