// eslint-disable-next-line import/prefer-default-export
export const getClientDateRangeTz = (startDate, endDate) => {
  const clientStartDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * -1 * 60 * 1000);
  clientStartDate.setUTCHours(0, 0, 0, 0);

  const clientEndDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * -1 * 60 * 1000);
  clientEndDate.setUTCHours(23, 59, 59, 999);

  return [clientStartDate.toISOString().slice(0, -1), clientEndDate.toISOString().slice(0, -1)];
};
