import { BULK_UPLOAD } from '@oup/shared-node-browser/constants.js';

const columnErrors = Object.values(BULK_UPLOAD.COLUMN_ERROR_MAP).reduce(
  (acc, value) => ({ ...acc, [value]: value }),
  {}
);
const fileErrors = Object.values(BULK_UPLOAD.ERROR_MAP).reduce((acc, value) => ({ ...acc, [value]: value }), {});
export const allErrors = { ...columnErrors, ...fileErrors };

const errorPriority = [
  allErrors.LINE_EMPTY,
  allErrors.INVALID_TOO_MANY_RECORDS,
  allErrors.INVALID_EMPTY_FILE,
  allErrors.INVALID_HEADER_ROW,
  allErrors.INVALID_NUMBER_OF_COLUMNS,
  allErrors.INVALID_NOT_ENOUGH_LICENCES
];

export default function getError(data, CMS, userTypeToEnrol, availableCredits) {
  let errorFound = '';
  const hasError = (error, errorData) => errorData.some(errorArray => errorArray.cmsErrorKeys.includes(error));

  errorPriority.forEach(error => {
    if (hasError(error, data)) {
      errorFound = error;
    }
  });

  const errorMapping =
    {
      [allErrors.LINE_EMPTY]: {
        type: allErrors.LINE_EMPTY,
        title: CMS.error_message_uploadError_intro_text,
        subtitle: `${CMS.error_message_uploadError_line_empty_subtitle} ${
          userTypeToEnrol
            ? CMS.error_message_uploadError_see_example_below_csv_file_subtitle
            : CMS.error_message_uploadError_see_example_below_subtitle
        }`,
        showExampleFileStructure: true
      },
      [allErrors.INVALID_TOO_MANY_RECORDS]: {
        type: allErrors.INVALID_TOO_MANY_RECORDS,
        title: CMS.file_upload_error_title,
        subtitle: CMS.file_upload_error_subtitle_invalid_too_many_records?.replace('[maxRecordsNumber]', 1000)
      },
      [allErrors.INVALID_NOT_ENOUGH_LICENCES]: {
        type: allErrors.INVALID_NOT_ENOUGH_LICENCES,
        title: CMS.file_upload_error_title,
        subtitle: CMS.file_upload_error_subtitle_invalid_not_enough_licences?.replace(
          '[availableCredits]',
          availableCredits
        ),
        showLicencesHelpLink: true
      },
      [allErrors.INVALID_EMPTY_FILE]: {
        type: allErrors.INVALID_EMPTY_FILE,
        title: CMS.file_upload_error_title,
        subtitle: CMS.file_upload_error_subtitle_invalid_empty_file
      },
      [allErrors.INVALID_HEADER_ROW]: {
        type: allErrors.INVALID_HEADER_ROW,
        title: CMS.file_upload_error_title,
        subtitle: CMS.file_upload_error_subtitle_invalid_number_of_columns,
        showExampleFileStructure: true
      },
      [allErrors.INVALID_NUMBER_OF_COLUMNS]: {
        type: allErrors.INVALID_NUMBER_OF_COLUMNS,
        title: CMS.file_upload_error_title,
        subtitle: CMS.file_upload_error_subtitle_invalid_number_of_columns,
        showExampleFileStructure: true
      },
      DEFAULT: {
        type: 'DEFAULT',
        title: CMS.error_message_uploadError_intro_text,
        subtitle: CMS.error_message_other_subtitle,
        errors: data.map(({ row, cmsErrorKeys }) => ({
          rowNumber: row,
          error: cmsErrorKeys.map(val => CMS[val]).join(', ')
        }))
      }
    }[errorFound || 'DEFAULT'] || {};

  return errorMapping;
}
