/* eslint-disable react/jsx-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { getHeaderColumnsAndValidationRules } from '@oup/shared-node-browser/bulkUsersValidationMapping.js';
import { allErrors } from './errorMapping.js';
import style from './ExampleFileStructure.scss';

function ExampleFileStructure({ type, orgRole, userTypeToEnrol }) {
  const { expectedColumns, optionalColumns } = getHeaderColumnsAndValidationRules(orgRole, userTypeToEnrol);
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const noContent = type === allErrors.INVALID_EMPTY_FILE || allErrors.INVALID_HEADER_ROW;
  const isEmptyLine = type === allErrors.LINE_EMPTY;
  const isOptional = column => optionalColumns.includes(column);

  return (
    <div className={style.exampleFileStructure}>
      <table>
        <tbody>
          <tr>
            <th />
            {expectedColumns.map((column, key) => (
              <th key={`${column}-key`}>{letters[key]}</th>
            ))}
          </tr>
          <tr>
            <th>1</th>
            {expectedColumns.map((column, key) => (
              <td key={`${column}-${key}`}>
                {column}
                {isOptional(column) && <sup className={style.optional}>*</sup>}
              </td>
            ))}
          </tr>
          {(!noContent || isEmptyLine) && (
            <>
              <tr>
                <th>2</th>
                {expectedColumns.map((column, key) => (
                  <td key={`${column}-${key}`}>
                    <span className={style.value} />
                  </td>
                ))}
              </tr>
              {isEmptyLine && (
                <tr className={style.empty}>
                  <th>3</th>
                  {expectedColumns.map((column, key) => (
                    <td key={`${column}-${key}`} />
                  ))}
                </tr>
              )}
              <tr>
                <th>{isEmptyLine ? 4 : 3}</th>
                {expectedColumns.map((column, key) => (
                  <td key={`${column}-${key}`}>{!isOptional(column) && <span className={style.value} />}</td>
                ))}
              </tr>
              <tr>
                <th>{isEmptyLine ? 5 : 4}</th>
                {expectedColumns.map((column, key) => (
                  <td key={`${column}-${key}`}>
                    <span className={style.value} />
                  </td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </table>

      {optionalColumns.length > 0 && (
        <span className={style.note}>
          <sup className={style.optional}>*</sup> Required column with optional row value
        </span>
      )}
    </div>
  );
}

ExampleFileStructure.propTypes = {
  type: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  userTypeToEnrol: PropTypes.string.isRequired
};

export default ExampleFileStructure;
