import { put } from 'redux-saga/effects';
import qs from 'query-string';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { getProductMetadata } from '../../../../../utils/platform';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import WebStorage from '../../../../../utils/storage/WebStorage';

const webStorage = new WebStorage('orb-class-report');

export const getClassReportUrl = (orgId, classId, locationId, subject, academicYear, startDate, endDate) => {
  const url = featureIsEnabled('orb-class-report-date-selector')
    ? `${__API_BASE__}/org/${orgId}/class/${classId}/class-report?${qs.stringify({
        locationId,
        subject,
        academicYear,
        startDate,
        endDate
      })}`
    : `${__API_BASE__}/org/${orgId}/class/${classId}/class-report?${qs.stringify({
        locationId,
        subject,
        academicYear
      })}`;
  return url;
};

function* getClassReport(orgId, classId, locationId, academicYear, startDate, endDate) {
  const subject = getProductMetadata('orb', 'subject');
  const response = yield signedFetch(
    'getClassReport',
    getClassReportUrl(orgId, classId, locationId, subject, academicYear, startDate, endDate),
    'GET'
  );

  yield put(
    actions.setReporting({
      classroomId: classId,
      studentId: webStorage.get()?.[orgId]?.[classId]?.studentId
    })
  );

  if (response.status === 'success') {
    const { dials, levels, students } = response.data;
    yield put(actions.gradebookClassDialReportSuccess({ data: dials, classId }));
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      const data = { levels, students };
      yield put(actions.gradebookGroupClassReportSuccessV2(data, orgId));
    } else {
      yield put(actions.gradebookGroupClassReportSuccess({ levels, students }));
    }
    return;
  }
  if (featureIsEnabled('mat-report-change-for-reducers')) {
    yield put(actions.gradebookGroupClassReportFailureV2(response.message, response.code, orgId));
  } else {
    yield put(actions.gradebookGroupClassReportFailure(response.message, response.code));
  }
}

export default getClassReport;
