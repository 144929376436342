import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { resetForm, showBulkUserInput } from '../../../../../../../redux/reducers/enrolUser.reducer.js';
// Components
import ScrollContainer from '../../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import PopoutPanelIllustrationHeading from '../../../../../../../components/PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading.js';
import ExampleFileStructure from './ExampleFileStructure.js';
import ErrorsTable from './ErrorsTable.js';
// Utils
import content from '../../../../../../../utils/cmsContent.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../../../../../globals/hubConstants.js';
import getOptProductsCatalogueUrl from '../../../../../../../utils/opt/getOptProductsCatalogueUrl.js';
import getError from './errorMapping.js';
// Styling
import styles from '../enrollUser.scss';

function EnrolUserValidationError({
  closePanel,
  resetAction,
  bulkUserInputAction,
  data,
  orgId,
  orgRole,
  availableCredits = 0,
  userTypeToEnrol
}) {
  const CMS = content.enrolUserBulkPage || {};

  const closePanelAndResetForm = () => {
    closePanel();
    setTimeout(resetAction, 300);
  };

  const {
    type,
    title,
    subtitle,
    showExampleFileStructure = false,
    showLicencesHelpLink = false,
    errors = []
  } = getError(data, CMS, userTypeToEnrol, availableCredits);

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink
              isLhs={false}
              text={CMS.close_panel_text}
              action={userTypeToEnrol ? bulkUserInputAction : closePanelAndResetForm}
            />
          </div>
          <PopoutPanelIllustrationHeading
            title={title}
            subtitle={subtitle}
            illustrationSrc={HubIllustrationConstants.ERROR}
            illustrationAltText={HubIllustrationAltText.ERROR}
          >
            {showLicencesHelpLink && (
              <TextLink to={getOptProductsCatalogueUrl(orgId)} target="_blank">
                {CMS.file_upload_error_not_enough_licences_buy_more_link_text}
              </TextLink>
            )}
          </PopoutPanelIllustrationHeading>
        </div>
      }
      footerContent={<PopoutNavFooter backAction={userTypeToEnrol ? bulkUserInputAction : resetAction} />}
    >
      {showExampleFileStructure && (
        <div className={styles.errorListContainer}>
          <ExampleFileStructure type={type} orgRole={orgRole} userTypeToEnrol={userTypeToEnrol} />
        </div>
      )}
      {errors.length > 0 && <ErrorsTable errors={errors} />}
    </ScrollContainer>
  );
}

EnrolUserValidationError.propTypes = {
  closePanel: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  bulkUserInputAction: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.string.isRequired,
      value: PropTypes.string,
      cmsErrorKeys: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  orgId: PropTypes.string.isRequired,
  orgRole: PropTypes.string.isRequired,
  userTypeToEnrol: PropTypes.string.isRequired,
  availableCredits: PropTypes.number
};

export default connect(
  state => ({
    data: state.enrolUser.failedEntries,
    orgId: state.organisationPage.orgId,
    orgRole: state.enrolUser.orgRole,
    userTypeToEnrol: state.enrolUser.userTypeToEnrol,
    availableCredits: state.enrolUser.availableCredits
  }),
  {
    resetAction: resetForm,
    bulkUserInputAction: showBulkUserInput
  }
)(EnrolUserValidationError);
