import signedFetch from '../util/signedFetch.js';

export default ({ status }) => {
  const queryParams = new URLSearchParams();
  if (status) queryParams.set('status', status);

  const queryString = queryParams.toString();
  const URL = `${__API_BASE__}/user/tasks${queryString ? `?${queryString}` : ''}`;

  return signedFetch('getUserTasks', URL, 'GET');
};
