export const SOURCES = {
  ELTCORE: 'eltcore',
  ELTBOOK: 'eltbook',
  VST: 'vst',
  CBA: 'cba',
  OLB: 'olb',
  PROD: 'prod',
  UAT: 'uat',
  LOCALHOST: 'localhost'
};

export const NA = 'N/A';
