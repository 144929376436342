import * as t from '../actionTypes';

export const addOfflineProducts = products => ({
  type: t.ADD_OFFLINE_PRODUCTS,
  payload: products
});

export const getOfflineProducts = (searchParams = null) => ({
  type: t.GET_OFFLINE_PRODUCTS,
  payload: searchParams
});

export const loadOfflineProducts = products => ({
  type: t.LOAD_OFFLINE_PRODUCTS,
  payload: products
});

export const addOfflineProfiles = profiles => ({
  type: t.ADD_OFFLINE_PROFILES,
  payload: profiles
});

export const deleteOfflineProfiles = profileIds => ({
  type: t.DELETE_OFFLINE_PROFILES,
  payload: profileIds
});

export const getOfflineProfiles = (searchParams = null) => ({
  type: t.GET_OFFLINE_PROFILES,
  payload: searchParams
});

export const loadOfflineProfiles = profiles => ({
  type: t.LOAD_OFFLINE_PROFILES,
  payload: profiles
});

export const addOfflineUnits = units => ({
  type: t.ADD_OFFLINE_UNITS,
  payload: units
});

export const deleteOfflineUnits = payload => ({
  type: t.DELETE_OFFLINE_UNITS,
  payload
});

export const updateDownloadOfflineUnitStatus = payload => ({
  type: t.UPDATE_DOWNLOAD_OFFLINE_STATUS,
  payload
});

export const getOfflineUnits = (searchParams = null) => ({
  type: t.GET_OFFLINE_UNITS,
  payload: searchParams
});

export const loadOfflineUnits = units => ({
  type: t.LOAD_OFFLINE_UNITS,
  payload: units
});

export const loadOfflinePendingActionsRequest = data => ({
  type: t.GET_OFFLINE_PENDING_ACTIONS_REQUEST,
  payload: data
});

export const loadOfflinePendingActionsSuccess = data => ({
  type: t.GET_OFFLINE_PENDING_ACTIONS_SUCCESS,
  payload: data
});

export const loadOfflinePendingActionsFailure = data => ({
  type: t.GET_OFFLINE_PENDING_ACTIONS_FAILURE,
  payload: data
});

export const clearOfflinePendingActionsRequest = data => ({
  type: t.CLEAR_OFFLINE_PENDING_ACTIONS_REQUEST,
  payload: data
});

export const getPendingActionsTime = data => ({
  type: t.GET_PENDING_ACTIONS_TIME,
  payload: data
});

export const loadPendingActionsTime = data => ({
  type: t.LOAD_PENDING_ACTIONS_TIME,
  payload: data
});

export const setPendingActionsTime = data => ({
  type: t.SET_PENDING_ACTIONS_TIME,
  payload: data
});

export const getOfflinePlayerVersion = () => ({
  type: t.GET_OFFLINE_PLAYER_VERSION
});

export const updateOfflinePlayerVersion = version => ({
  type: t.UPDATE_OFFLINE_PLAYER_VERSION,
  payload: version
});

export const loadOfflinePlayerVersion = version => ({
  type: t.LOAD_OFFLINE_PLAYER_VERSION,
  payload: version
});

export const setOfflineDownloadProgress = payload => ({
  type: t.SET_OFFLINE_DOWNLOAD_PROGRESS,
  payload
});

export const removeOfflineDownloadProgress = payload => ({
  type: t.REMOVE_OFFLINE_DOWNLOAD_PROGRESS,
  payload
});

export const getLicenseStatusRequest = data => ({
  type: t.GET_OFFLINE_LICENSE_REQUEST,
  payload: data
});

export const getLicenseStatusSuccess = data => ({
  type: t.GET_OFFLINE_LICENSE_SUCCESS,
  payload: data
});

export const getLicenseStatusFailure = data => ({
  type: t.GET_OFFLINE_LICENSE_FAILURE,
  payload: data
});

export const getOfflineLicenseStatusTimer = timestamp => ({
  type: t.GET_OFFLINE_LICENSE_STATUS_TIMER,
  payload: timestamp
});

export const setOfflineLicenseStatusTimer = timestamp => ({
  type: t.SET_OFFLINE_LICENSE_STATUS_TIMER,
  payload: timestamp
});

export const loadOfflineLicenseStatusTimer = data => ({
  type: t.LOAD_OFFLINE_LICENSE_STATUS_TIMER,
  payload: data
});
