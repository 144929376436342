import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { orgRoles as OrgRoles } from '../globals/orgRoles';
import USER_ROLES, { getRoleLabelPlural } from '../globals/userRoles';
import { PAGE_SIZE } from '../globals/pagination';
import {
  initialiseSelectionView,
  setLimit,
  setLimitToggle,
  updateSelection,
  clearSelection
} from '../redux/reducers/selection';

import UserSelectionView from '../views/UserSelectionView';
import { searchStudentSortOptions } from '../globals/searchFilters';
import withLocalizedContent from '../language/withLocalizedContent';
import { featureIsEnabled } from '../globals/envSettings';
import { isOrbMode } from '../utils/platform';

import {
  initialiseInstance,
  setFilter,
  setPage,
  setReveal,
  setSort,
  setTerm,
  setFilterOptions,
  triggerSearch
} from '../redux/reducers/data/search.reducer';
import getUsersGroupedByRole from '../redux/utils/getUsersGroupedByRole';
import withGetYearGroupOptions from '../language/withGetYearGroupOptions';

const ORDERED_ROLES = [
  USER_ROLES.MANAGED_USER,
  USER_ROLES.LEARNER,
  USER_ROLES.TEACHER,
  USER_ROLES.TEACHER_ADMIN,
  USER_ROLES.ORG_ADMIN
];

const INITIALLY_SELECTED_ROLES = [USER_ROLES.MANAGED_USER, USER_ROLES.LEARNER];

class StudentSelectionPanel extends Component {
  componentDidMount() {
    const {
      classId,
      orgId,
      initialRoles,
      initialiseSelectionViewAction,
      context,
      userType,
      classroomEditingState,
      initialFilter,
      initialLimitToggleValue,
      initialLimitValue,
      initialiseSearch
    } = this.props;

    const searchObject = {
      orgId,
      archived: false,
      active: true,
      invited: true,
      roles: initialRoles
    };

    initialiseSelectionViewAction(
      context,
      userType,
      classroomEditingState.selectedStudentIds,
      initialFilter,
      initialLimitToggleValue,
      initialLimitValue
    );

    if (classId) {
      searchObject.classId = classId;
    }
    initialiseSearch('userSelection', true, searchObject);
  }

  componentDidUpdate() {
    const { filterOptions, orgRole, initialRoles, setFilterOptionsAction } = this.props;
    if (!filterOptions.length) {
      const initialFilterOptions = ORDERED_ROLES.filter(
        role =>
          (orgRole === OrgRoles.PRIMARY_SCHOOL && role !== USER_ROLES.LEARNER) ||
          (orgRole === OrgRoles.SECONDARY_SCHOOL && role !== USER_ROLES.MANAGED_USER)
      ).map(role => ({
        text: getRoleLabelPlural(role),
        value: role,
        checked: initialRoles.includes(role)
      }));
      setFilterOptionsAction(initialFilterOptions);
    }
  }

  render() {
    const {
      localizedContent,
      orgId,
      closePopoutAction,
      initialLimitToggleValue,
      initialLimitValue,
      submitAction,
      backAction,
      usersByRole,
      selectedIds,
      updateSelectionAction,
      clearSelectionAction,
      limitToggleValue,
      limitValue,
      setLimitAction,
      setLimitToggleAction,
      setFilterAction,
      setSearchTerm,
      setSortAction,
      sort,
      context,
      existingClassroom = {},
      classroomEditingState,
      isLoading,
      yearGroupOptions,
      orgRole,
      page,
      totalResults,
      setPageAction,
      isOnlineTestStudentsPanel,
      filterOptions,
      testLicenceStudentsList = null,
      isPlacementTest,
      setShowAddStudentsPanel,
      placementTestSessionName
    } = this.props;

    const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
    const { studentIdList = [], teacherIdList = [] } = existingClassroom;
    const classroomName = existingClassroom.name || classroomEditingState.classroomNameValue;
    const numberOfExistingStudents = studentIdList.length;
    const disabledIds = [...studentIdList, ...teacherIdList];
    const initialSelectedIds = classroomEditingState.selectedStudentIds;
    const isPrimarySchool = orgRole === OrgRoles.PRIMARY_SCHOOL;

    return (
      <UserSelectionView
        context={context}
        isPrimarySchool={isPrimarySchool}
        orgId={orgId}
        toggleRender={toggleRender && !isOnlineTestStudentsPanel}
        classroomName={classroomName || placementTestSessionName}
        initialSelectedIds={initialSelectedIds}
        closeAction={closePopoutAction}
        backAction={backAction}
        nextAction={submitAction}
        initialLimitToggleValue={initialLimitToggleValue}
        initialLimitValue={initialLimitValue}
        externalLimitBaseValue={numberOfExistingStudents}
        disabledIds={!isOnlineTestStudentsPanel ? disabledIds : []}
        usersByRole={usersByRole}
        selectedIds={selectedIds}
        updateSelectionAction={updateSelectionAction}
        clearSelectionAction={clearSelectionAction}
        limitToggleValue={limitToggleValue}
        limitValue={limitValue}
        setLimitAction={setLimitAction}
        setLimitToggleAction={setLimitToggleAction}
        setFilterAction={setFilterAction}
        setSearchTerm={term => setSearchTerm(term, yearGroupOptions)}
        showLimitControls
        setSortFilter={setSortAction}
        sortOptions={searchStudentSortOptions('classStudentSearch', sort, orgRole === OrgRoles.SECONDARY_SCHOOL)}
        isLoading={isLoading}
        filterOptions={filterOptions}
        initialSelectedValues={INITIALLY_SELECTED_ROLES}
        localizedContent={{
          ...localizedContent.studentSelectionPanel,
          clear_selection: localizedContent.productFinder.clear_selection
        }}
        isOnlineTestStudentsPanel={isOnlineTestStudentsPanel}
        page={page}
        size={PAGE_SIZE}
        totalResults={totalResults}
        setPageAction={setPageAction}
        showYearGroup
        testLicenceStudentsList={testLicenceStudentsList}
        isPlacementTest={isPlacementTest}
        setShowAddStudentsPanel={setShowAddStudentsPanel}
      />
    );
  }
}

StudentSelectionPanel.propTypes = {
  orgId: PropTypes.string,
  closePopoutAction: PropTypes.func,
  initialLimitToggleValue: PropTypes.bool,
  initialLimitValue: PropTypes.number,
  submitAction: PropTypes.func,
  initialFilter: PropTypes.array,
  usersByRole: PropTypes.object,
  selectedIds: PropTypes.array,
  updateSelectionAction: PropTypes.func,
  clearSelectionAction: PropTypes.func,
  limitToggleValue: PropTypes.bool,
  limitValue: PropTypes.number,
  setLimitAction: PropTypes.func,
  setLimitToggleAction: PropTypes.func,
  setFilterAction: PropTypes.func,
  setSearchTerm: PropTypes.func,
  initialiseSearch: PropTypes.func,
  initialiseSelectionViewAction: PropTypes.func,
  setSortAction: PropTypes.func,
  sort: PropTypes.string,
  context: PropTypes.string,
  existingClassroom: PropTypes.object,
  classroomEditingState: PropTypes.object,
  userType: PropTypes.string,
  backAction: PropTypes.func,
  isLoading: PropTypes.bool,
  yearGroupOptions: PropTypes.object,
  orgRole: PropTypes.string,
  initialRoles: PropTypes.array,
  localizedContent: PropTypes.object,
  page: PropTypes.number,
  totalResults: PropTypes.number,
  setPageAction: PropTypes.func,
  classId: PropTypes.string,
  isOnlineTestStudentsPanel: PropTypes.bool,
  filterOptions: PropTypes.array,
  setFilterOptionsAction: PropTypes.func,
  testLicenceStudentsList: PropTypes.object,
  isPlacementTest: PropTypes.bool,
  setShowAddStudentsPanel: PropTypes.func,
  placementTestSessionName: PropTypes.string
};

export default compose(
  withGetYearGroupOptions,
  withLocalizedContent('studentSelectionPanel', 'productFinder'),
  connect(
    (state, { orgId, getYearGroupOptions }) => {
      const searchInstance = state.search.userSelection || {};
      const resultIds = searchInstance.ids || [];
      const organisation = state.organisations.data[orgId];
      const yearGroupOptions = getYearGroupOptions(organisation.curriculumType);
      const isLoading = searchInstance.loading;

      return {
        selectedIds: state.selection.selectedIds || [],
        limitToggleValue: state.selection.limitToggleValue,
        limitValue: state.selection.limitValue,
        searchTerm: searchInstance.term,
        sort: searchInstance.sort,
        page: searchInstance.page || 1,
        totalResults: searchInstance.totalResults,
        yearGroupOptions,
        filterOptions: searchInstance.filterOptions,
        isLoading,
        usersByRole: getUsersGroupedByRole(state, resultIds, ORDERED_ROLES),
        orgRole: state.organisations.data[orgId].role
      };
    },
    {
      initialiseSelectionViewAction: initialiseSelectionView,
      initialiseSearch: initialiseInstance,
      updateSelectionAction: updateSelection,
      clearSelectionAction: clearSelection,
      setLimitToggleAction: setLimitToggle,
      setLimitAction: setLimit,
      setSearchTerm: (term, yearGroupOptions) => setTerm('userSelection', term, yearGroupOptions),
      setSortAction: sort => setSort('userSelection', sort[0]),
      setPageAction: page => setPage('userSelection', page),
      setFilterAction: value => setFilter('userSelection', 'roles', value),
      setFilterOptionsAction: value => setFilterOptions('userSelection', value),
      setRevealAction: reveal => setReveal('userSelection', reveal),
      triggerSearchAction: triggerSearch.bind(null, 'userSelection')
    }
  )
)(StudentSelectionPanel);
