import React from 'react';

function TestPage() {
  return (
    <div>
      <h1>Test iframe Page</h1>
    </div>
  );
}

export default TestPage;
