import signedFetch from './util/signedFetch.js';

export default (orgId, orgType, isPlacementCentre, userTypeToEnrol, body) => {
  const formData = new FormData();
  const blob = new Blob([body.fileContents], { type: body.fileType });
  formData.append('fileContents', blob, body.fileName);

  return signedFetch(
    'bulkUsersValidation',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/${encodeURIComponent(orgType)}/users/${encodeURIComponent(
      userTypeToEnrol
    )}/bulk-validation/?isPlacementCentre=${encodeURIComponent(isPlacementCentre)}`,
    'POST',
    formData,
    null,
    false,
    true
  );
};
