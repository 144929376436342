import React, { useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import { ICON_INTEGRATION } from '@oup/shared-front-end/src//svg/oup/index.js';
import Modal from '@oup/shared-front-end/src/components/Modal/Modal.js';
import ModalHead from '@oup/shared-front-end/src/components/Modal/ModalHead';
import ModalBody from '@oup/shared-front-end/src/components/Modal/ModalBody';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup/RadioButtonGroup';
import Select from '@oup/shared-front-end/src/components/Select/Select';
import RangeSlider from '@oup/shared-front-end/src/components/RangeSlider/RangeSlider';
import breakpoints from '../../globals/breakpoints';
import withBreakpoint from '../../decorators/withBreakpoint';
// Styles
import styles from './GradebookProgressSettingsModal.scss';

function GradebookProgressSettingsModal({ content, settings = {}, breakpoint }) {
  const [progressSettingsOpen, setProgressSettingsOpen] = useState(false);
  return (
    <div>
      <Button
        text={content.progress_settings}
        variant="outline"
        size="base"
        icon={{ component: <ICON_INTEGRATION /> }}
        onClick={() => setProgressSettingsOpen(!progressSettingsOpen)}
      />
      <Modal
        id="gradebook-progress-settings"
        labelledbyId="gradebook-progress-settings-heading"
        open={progressSettingsOpen}
        variant="default"
        size="small"
        closeHandler={() => setProgressSettingsOpen(false)}
      >
        <ModalHead
          onClose={() => setProgressSettingsOpen(false)}
          heading={content.progress_settings}
          headingId="gradebook-progress-settings-heading"
        />
        <ModalBody>
          {/* "Levels" selector */}
          {settings.level && breakpoint === breakpoints.XXS && (
            <div
              className={classnames(styles.filterBlock, styles.levelSelector, {
                [styles.hiddenLevel]: settings.level.isActivityLevel
              })}
            >
              <Select
                id="select-level"
                name="select-level"
                options={settings.level.options}
                onChange={e => {
                  settings.level.onChange(e.target.value);
                  setProgressSettingsOpen(false);
                }}
                label={content.show_results_for_level}
                value="default"
              />
            </div>
          )}

          {/* "Scores" attempt filter */}
          {settings.attempt && (
            <div className={styles.filterBlock}>
              <Select
                id="select-attempt"
                name="select-attempt"
                options={settings.attempt.options}
                onChange={e => settings.attempt.onChange(e.target.value)}
                label={content.scores}
                value={settings.attempt.value}
              />
            </div>
          )}
          {/* "Show figures as" percentages/fractions filter */}
          {settings.score && (
            <div className={styles.filterBlock}>
              <RadioButtonGroup
                name="show-figures-as"
                checkedValue={settings.score.displayAs === 'percentages' ? ['percentages'] : ['fractions']}
                variant="outline"
                hideLegend={false}
                legend={content.show_figures_as}
                radioGroup={[
                  {
                    label: content.percentages,
                    value: 'percentages',
                    id: 'option-percentages'
                  },
                  {
                    label: content.fractions,
                    value: 'fractions',
                    id: 'option-fractions'
                  }
                ]}
                onChange={e => settings.score.onChange(e.target.value === 'percentages')}
                classnames={{ container: styles.radioOptionsInline }}
              />
            </div>
          )}
          {/* Show scores out of (all activities / completed only) */}
          {settings.completedActivities && (
            <div className={styles.filterBlock}>
              <RadioButtonGroup
                id="use-completed-only"
                variant="outline"
                hideLegend={false}
                legend={content.show_scores_for}
                name="use-completed-only"
                radioGroup={[
                  {
                    label: content.all_activities,
                    value: false,
                    id: 'option-all-activities'
                  },
                  {
                    label: content.completed_activities,
                    value: true,
                    id: 'option-completed-only'
                  }
                ]}
                checkedValue={[settings.completedActivities.value]}
                onChange={() => settings.completedActivities.onChange(!settings.completedActivities.value)}
                classnames={{ container: styles.radioOptionsInline }}
              />
            </div>
          )}

          {/* "Highlight scores" filter */}
          {settings.highlightScores && (
            <div className={classnames(styles.filterBlock, styles.highlightScores)}>
              <RangeSlider
                id="gradebook_highlight_scores"
                name="gradebook_highlight_scores"
                legend={content.highlight_by_score}
                hideLegend={false}
                description={content.highlight_by_score_description}
                hideDescription={false}
                step={10}
                initialValue={settings.highlightScores.value}
                onChange={settings.highlightScores.onChange}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

GradebookProgressSettingsModal.propTypes = {
  breakpoint: PropTypes.string,
  content: PropTypes.object,
  settings: PropTypes.shape({
    score: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      displayAs: PropTypes.oneOf(['percentages', 'fractions'])
    }),
    attempt: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired
        })
      ).isRequired,
      value: PropTypes.string
    }),
    completedActivities: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.bool
    }),
    highlightScores: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.bool
    }),
    level: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          disabled: PropTypes.bool
        })
      ).isRequired,
      isActivityLevel: PropTypes.bool
    })
  })
};

export default compose(withBreakpoint)(GradebookProgressSettingsModal);
