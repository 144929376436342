import { put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';
import { featureIsEnabled } from '../../../../../globals/envSettings';
import WebStorage from '../../../../../utils/storage/WebStorage';

const webStorage = new WebStorage('orb-class-report');

export const getClassReportEnvironmentUrl = orgId => `${__API_BASE__}/org/${orgId}/class-report-environment`;

function* getClassReportEnvironment(orgId) {
  const response = yield signedFetch('getClassReportEnvironment', getClassReportEnvironmentUrl(orgId), 'GET');

  if (response.status === 'success') {
    if (featureIsEnabled('mat-report-change-for-reducers')) {
      if (!response.data.locationId) {
        response.data.locationId = 'uk';
      }

      const classroomId = webStorage.get()?.[orgId]?.classroomId;

      webStorage.merge({ orgId });
      yield put(
        actions.setReporting({
          orgId,
          classroomId,
          studentId: webStorage.get()?.[orgId]?.[classroomId]?.studentId
        })
      );

      yield put(actions.gradebookClassReportEnvironmentSuccessV2(response.data, orgId));
    } else {
      yield put(actions.gradebookClassReportEnvironmentSuccess(response.data));
    }
    return;
  }

  yield put(actions.setReporting({ orgId: null, classroomId: null, studentId: null }));

  if (featureIsEnabled('mat-report-change-for-reducers')) {
    const orgName = yield select(state => state.organisations.data[orgId]?.name);
    const locationId = 'uk';
    yield put(actions.gradebookClassReportEnvironmentFailureAddOrgDetails(orgId, orgName, locationId));
    yield put(actions.gradebookClassReportEnvironmentFailureV2(response.message, response.code, orgId));
  } else {
    yield put(actions.gradebookClassReportEnvironmentFailure(response.message, response.code));
  }
}

export default getClassReportEnvironment;
