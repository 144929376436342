import { select, put } from 'redux-saga/effects';

import bulkUsersEnrolmentApi from '../../../../apiCalls/bulkUsersEnrolmentApi.js';
import { bulkShowConfirmation } from '../../../../../reducers/enrolUser.reducer.js';
import { getCurrentPlatform } from '../../../../../../utils/platform.js';

export default function* bulkReviewableFormSubmit({ orgId, orgRole, userTypeToEnrol, isPlacementCentre }) {
  console.log('[enrolUser Saga] bulkReviewableFormSubmit');

  const { entries } = yield select(state => ({
    entries: state.enrolUser.entries
  }));

  const transformedRecords = entries.map(entry => ({
    ...entry,
    temp_id: undefined,
    validationErrors: undefined,
    usernameChecking: undefined
  }));

  const response = yield bulkUsersEnrolmentApi(orgId, orgRole, isPlacementCentre, userTypeToEnrol, {
    orgId,
    entries: transformedRecords,
    platformCode: getCurrentPlatform()
  });

  if (response.status === 'success') {
    console.log('[bulkReviewableFormSubmit Saga] Showing confirmation screen');
    yield put(bulkShowConfirmation());
  } else {
    console.log('[bulkReviewableFormSubmit Saga] Showing error screen');
  }
}
