import JSPDF from 'jspdf';
import { CARD_CONSTANTS, CARD_STYLES, SIGN_IN_CARD_USER_STATUS } from './appConstants';
import content from '../utils/cmsContent.js';

const cardPerPage = 10;
const cardWidth = 104;
const cardHeight = 59;
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');
const chunkSize = 10;
let style = null;
let images = null;

/**
 * @desc Convert hex code #000000 to RGB 255, 255, 255.
 * @param {string} hex
 * @return {Object}
 */
const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

/**
 * Get width of the given text.
 * @param {string} text
 * @param {string} font
 * @return {number}
 */

const getTextWidth = (text, font) => {
  context.font = font;
  const metrics = context.measureText(text);
  context.clearRect(0, 0, canvas.width, canvas.height);
  return metrics.width;
};

/**
 * Returns trimmed text and width of the text in pt.
 * @param {Object} text
 */

const formattedHeader = text => ({
  text: text.HEADER.TEXT.trim(),
  width: Math.round(getTextWidth(text.HEADER.TEXT, text.HEADER.FONT) * 0.75)
});

/**
 * Convert image to base64 format.
 * @param {string} src
 */

const addImageProcess = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      context.drawImage(img, 0, 0);
      const b64 = canvas.toDataURL('image/png', 0.2);
      resolve(b64);
    };
    img.onerror = reject;
    img.src = src;
  });

/**
 * Funtion to add page and image
 * @param {any} doc
 * @param {string} imagebase64
 * @param {number} i
 */

const addPage = (doc, imagebase64, i) => {
  if (i === 0) {
    doc.addImage(imagebase64, 'PNG', 0, 0, 208, 295, null, 'FAST');
  } else {
    doc.addPage();
    doc.addImage(imagebase64, 'PNG', 0, 0, 208, 295, null, 'FAST');
  }
};

/**
 * Funtion to print card title
 * @param {any} doc
 * @param {{text:string,width:number}} header
 * @param {Object[]} users
 */

const cardTitle = (doc, header, users) => {
  let row = 1;
  const magrinLeft = 4;
  users.forEach((user, i) => {
    const index = i + 1;
    const headerXCoordinate = Math.round(header.width / 4) + magrinLeft;
    const extraMargin = headerXCoordinate < cardWidth / 2 ? cardWidth / 2 - headerXCoordinate : 0;
    const xCo = index % 2 === 0 ? cardWidth + headerXCoordinate + extraMargin : headerXCoordinate + extraMargin;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yCo = 16 + cardHeight * (row - 1);
    const { HEADER } = style;
    const { r, g, b } = hexToRgb(HEADER.COLOR);

    doc.setFont(undefined, HEADER.FONT_TYPE);
    doc.setFontSize(HEADER.FONT_SIZE);
    doc.setTextColor(r, g, b);
    doc.text(xCo, yCo, header.text, 'center');
  });
};

/**
 * Funtion to print school code label and value
 * @param {any} doc
 * @param {Object[]} users
 */

const getSchoolCode = (doc, users) => {
  let row = 1;
  users.forEach((user, i) => {
    const index = i + 1;
    const xCo = index % 2 === 0 ? 115 : 10;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yCo = 24 + cardHeight * (row - 1);
    const { LABEL, VALUE } = style;
    const { r: lr, g: lg, b: lb } = hexToRgb(LABEL.COLOR);
    const { r: vr, g: vg, b: vb } = hexToRgb(VALUE.COLOR);
    doc.setFont(undefined, LABEL.FONT_TYPE);
    doc.setFontSize(LABEL.FONT_SIZE);
    doc.setTextColor(lr, lg, lb);
    doc.text(xCo, yCo, CARD_CONSTANTS.SCHOOL_CODE);
    doc.setFont(undefined, VALUE.FONT_TYPE);
    doc.setFontSize(VALUE.FONT_SIZE);
    doc.setTextColor(vr, vg, vb);
    doc.text(xCo, yCo + 4, user.userName.split('_')[0]);
  });
};

/**
 * Funtion to print username label and value
 * @param {any} doc
 * @param {Object[]} users
 */

const getUserName = (doc, users) => {
  let row = 1;
  users.forEach((user, i) => {
    const index = i + 1;
    const xCo = index % 2 === 0 ? 115 : 10;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yCo = 34 + cardHeight * (row - 1);
    const { LABEL, VALUE } = style;
    const { r: lr, g: lg, b: lb } = hexToRgb(LABEL.COLOR);
    const { r: vr, g: vg, b: vb } = hexToRgb(VALUE.COLOR);
    doc.setFont(undefined, LABEL.FONT_TYPE);
    doc.setFontSize(LABEL.FONT_SIZE);
    doc.setTextColor(lr, lg, lb);
    doc.text(xCo, yCo, CARD_CONSTANTS.USERNAME);
    doc.setFont(undefined, VALUE.FONT_TYPE);
    doc.setFontSize(VALUE.FONT_SIZE);
    doc.setTextColor(vr, vg, vb);
    const splitUserName = doc.splitTextToSize(user.userName.split('_')[1], 85);
    doc.text(xCo, yCo + 4, splitUserName);
  });
};

/**
 * Funtion to print password label and value
 * @param {any} doc
 * @param {Object[]} users
 */

const getPassword = (doc, users) => {
  let row = 1;
  users.forEach((user, i) => {
    const index = i + 1;
    const xCo = index % 2 === 0 ? 115 : 10;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yC = user.userName.split('_')[1].length > 36 ? 47 : 44;
    const yCo = yC + cardHeight * (row - 1);
    const { LABEL, VALUE } = style;
    const { r: lr, g: lg, b: lb } = hexToRgb(LABEL.COLOR);
    const { r: vr, g: vg, b: vb } = hexToRgb(VALUE.COLOR);
    doc.setFont(undefined, LABEL.FONT_TYPE);
    doc.setFontSize(LABEL.FONT_SIZE);
    doc.setTextColor(lr, lg, lb);
    doc.text(xCo, yCo, CARD_CONSTANTS.PASSWORD);
    doc.setFont(undefined, VALUE.FONT_TYPE);
    doc.setFontSize(VALUE.FONT_SIZE);
    doc.setTextColor(vr, vg, vb);
    doc.text(xCo, yCo + 4, user.password);
  });
};

/**
 * Funtion to print class label and value
 * @param {any} doc
 * @param {Object[]} users
 */

const getClass = (doc, users) => {
  let row = 1;
  users.forEach((user, i) => {
    const index = i + 1;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    if (user.class) {
      const xCo = index % 2 === 0 ? 180 : 75;
      const yCo = 24 + cardHeight * (row - 1);
      const { LABEL, CLASS } = style;
      const { r: lr, g: lg, b: lb } = hexToRgb(LABEL.COLOR);
      const { r, g, b } = hexToRgb(CLASS.COLOR);
      doc.setFont(undefined, LABEL.FONT_TYPE);
      doc.setFontSize(LABEL.FONT_SIZE);
      doc.setTextColor(lr, lg, lb);
      doc.text(xCo, yCo, CARD_CONSTANTS.CLASS);
      doc.setFont(undefined, CLASS.FONT_TYPE);
      doc.setFontSize(CLASS.FONT_SIZE);
      doc.setTextColor(r, g, b);
      doc.text(xCo, yCo + 3, doc.splitTextToSize(user.class, 20));
    }
  });
};

/**
 * Funtion to print error label and description
 * @param {any} doc
 * @param {Object[]} users
 */

const getErrorDescription = (doc, users) => {
  let row = 1;
  const magrinLeft = 4;
  users.forEach((user, i) => {
    const index = i + 1;
    const labelWidth = Math.round(getTextWidth(CARD_CONSTANTS.USERNAME, style.HEADER.FONT) * 0.75);

    const headerXCoordinate = Math.round(labelWidth / 4) + magrinLeft;
    const extraMargin = headerXCoordinate < cardWidth / 2 ? cardWidth / 2 - headerXCoordinate : 0;
    const xCo = index % 2 === 0 ? cardWidth + headerXCoordinate + extraMargin : headerXCoordinate + extraMargin;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yCo = 24 + cardHeight * (row - 1);
    const { LABEL, VALUE, PARA } = style;
    const { r: lr, g: lg, b: lb } = hexToRgb(LABEL.COLOR);
    const { r: vr, g: vg, b: vb } = hexToRgb(VALUE.COLOR);
    const { r: pr, g: pg, b: pb } = hexToRgb(PARA.COLOR);
    doc.setFont(undefined, LABEL.FONT_TYPE);
    doc.setFontSize(LABEL.FONT_SIZE);
    doc.setTextColor(lr, lg, lb);
    doc.text(xCo, yCo, CARD_CONSTANTS.ERROR_USERNAME_LABEL, 'center');
    doc.setFont(undefined, VALUE.FONT_TYPE);
    doc.setFontSize(VALUE.FONT_SIZE);
    doc.setTextColor(vr, vg, vb);
    doc.text(xCo, yCo + 4, user.userName.split('_')[1], 'center');
    doc.setFont(undefined, PARA.FONT_TYPE);
    doc.setFontSize(PARA.FONT_SIZE);
    let message = content.managedUserSignInCardPanel.sign_in_card_error_message;
    if (user.status) {
      if (user.status === SIGN_IN_CARD_USER_STATUS.ARCHIVED) {
        message = content.managedUserSignInCardPanel.sign_in_card_error_message_user_archived;
      } else if (user.status === SIGN_IN_CARD_USER_STATUS.ERROR && user.message) {
        if (user.message.indexOf('Password Policy Violated') > -1) {
          message = content.managedUserSignInCardPanel.sign_in_card_error_message_user_exist;
        }
      }
    }
    const splitText = doc.splitTextToSize(message, 75);
    doc.setTextColor(pr, pg, pb);
    doc.text(xCo, yCo + 20, splitText, 'center');
  });
};

/**
 * Funtion to print card image on the page
 * @param {any} doc
 * @param {Object[]} users
 * @param {string} imgBg
 */

const getCardImg = (doc, users, imgBg) => {
  let row = 1;
  users.forEach((user, i) => {
    const index = i + 1;
    const xCo = index % 2 === 0 ? 109 : 4;
    row = index % 2 !== 0 && index !== 1 ? row + 1 : row;
    const yCo = 1 + cardHeight * (row - 1);
    doc.addImage(imgBg, 'PNG', xCo, yCo, 96, 53, null, 'FAST');
  });
};

/**
 * Funtion to print success cards on the page
 * @param {any} doc
 * @param {Object[]} users
 * @param {Object} cardHeader
 */

const getSuccessCard = (doc, users, cardHeader) => {
  style = CARD_STYLES.SUCCESS;
  getCardImg(doc, users, images[1]);
  cardTitle(doc, cardHeader, users);
  getSchoolCode(doc, users);
  getUserName(doc, users);
  getPassword(doc, users);
  getClass(doc, users);
};

/**
 * Funtion to print error cards on the page
 * @param {any} doc
 * @param {Object[]} users
 * @param {Object} cardHeader
 */
const getErrorCard = (doc, users, cardHeader) => {
  style = CARD_STYLES.ERROR;
  getCardImg(doc, users, images[2]);
  cardTitle(doc, cardHeader, users);
  getErrorDescription(doc, users);
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param {Object[]} myArray {Array} array to split
 */
const chunkUsers = myArray => {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
};

/**
 * Funtion to print card with school code, username, password and class labeles and values
 * @param {Object} users
 */

const generateSigninCard = async (users, schoolCode) => {
  console.time('generateSigninCard');

  // Dimensions of A4
  const doc = new JSPDF('p', 'mm', [297, 210], true);
  const cardHeader = formattedHeader(CARD_STYLES.SUCCESS);
  const errorCardHeader = formattedHeader(CARD_STYLES.ERROR);
  const emptyCardHeader = formattedHeader(CARD_STYLES.EMPTY);
  if (images === null) {
    const imagebase64 = addImageProcess(CARD_CONSTANTS.PAGE_BG_IMG_URL);
    const successCardImg = addImageProcess(CARD_CONSTANTS.SUCCESS_CARD_IMG_URL);
    const warningCardImg = addImageProcess(CARD_CONSTANTS.ERROR_CARD_IMG_URL);
    images = await Promise.all([imagebase64, successCardImg, warningCardImg]);
  }

  const successUsers = users.successUsers || [];
  const archiveUsers = users.archiveUsers || [];

  if (archiveUsers && archiveUsers.length === 0 && successUsers && successUsers.length === 0) {
    const { HEADER } = CARD_STYLES.EMPTY;
    const { r, g, b } = hexToRgb(HEADER.COLOR);
    doc.setFont(undefined, HEADER.FONT_TYPE);
    doc.setFontSize(HEADER.FONT_SIZE);
    doc.setTextColor(r, g, b);
    doc.text(60, 30, emptyCardHeader.text);
  }

  let page = 0;
  if (archiveUsers && archiveUsers.length > 0) {
    const noOfArchivePages = Math.ceil(archiveUsers.length / cardPerPage);
    const resultArchiveUsers = chunkUsers(archiveUsers);
    for (let i = 0; i < noOfArchivePages; i += 1) {
      addPage(doc, images[0], page);
      page += 1;
      getErrorCard(doc, resultArchiveUsers[i], errorCardHeader);
    }
  }
  if (successUsers && successUsers.length > 0) {
    const noOfSuccessPages = Math.ceil(successUsers.length / cardPerPage);
    const resultSuccessUsers = chunkUsers(successUsers);
    for (let i = 0; i < noOfSuccessPages; i += 1) {
      addPage(doc, images[0], page);
      page += 1;
      getSuccessCard(doc, resultSuccessUsers[i], cardHeader);
    }
  }
  const fileName = successUsers && successUsers.length > 0 ? successUsers : archiveUsers;
  let pdfFileName;
  if (fileName && fileName.length > 1) {
    pdfFileName = fileName[0].userName.split('_')[0] || fileName[0].userName;
  } else {
    pdfFileName = schoolCode || 'org';
  }
  const blobPDF = new Blob([doc.output('blob')], { type: 'application/pdf' });
  doc.save(`${pdfFileName}_sign-in_card.pdf`);
  console.timeEnd('generateSigninCard');
  return { docSize: Math.round(blobPDF.size / 1000) };
};

export default generateSigninCard;
