import { pick } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import colors from '../globals/colors.js';
import withLocalizedContent from '../language/withLocalizedContent.js';
import PageHeading from '../components/PageHeading/PageHeading.js';
import Card from '../components/Card/Card.js';
import OicOrganizationForm from '../components/OicOrganizationForm/OicOrganizationForm.js';
import { mode as opMode } from '../redux/reducers/oicTestCredits.reducer.js';
import LtiLicenceCreditsForm from '../components/LtiLicenceCreditsForm/LtiLicenceCreditsForm.js';
import BackButton from '../components/LtiLicenceCreditsForm/BackButton.js';

function LtiLicenceCreditsPage({ localizedContent: { ltiLicenceCreditsForm: localizedContent }, organization }) {
  const CreditsComponent = {
    [opMode.SUCCESS]: (
      <Card headingText={localizedContent.credits_card_title} headingColor={colors.PROFILE}>
        <LtiLicenceCreditsForm orgId={organization.formData.orgId} orgName={organization.formData.orgName} />
      </Card>
    )
  }[organization.mode];

  return (
    <>
      <Helmet title={localizedContent.page_title} />
      <div className="grid pad-top4 pad-bot4">
        <div className="row gin-bot3">
          <div className="col sm10 pad-bot2 gin-bot4">
            <PageHeading title={localizedContent.page_title} subtitle={localizedContent.page_subtitle} />
          </div>
          <div className="col md8">
            <OicOrganizationForm organization={organization} />
            {CreditsComponent}
          </div>
        </div>

        <BackButton href={`/dashboard/org/${organization.formData.orgId}`} />
      </div>
    </>
  );
}

LtiLicenceCreditsPage.propTypes = {
  organization: PropTypes.object,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('ltiLicenceCreditsForm'),
  connect(state => ({
    ...pick(state.oicTestCredits, ['organization'])
  }))
)(LtiLicenceCreditsPage);
