import { put } from 'redux-saga/effects';
import { loadOfflineProfiles } from '../../../../actions/offlineContentPlayer';

function* addOfflineProfiles(profilesToAdd) {
  const storedValue = localStorage.getItem('offline_profiles');
  const profiles = storedValue ? JSON.parse(storedValue) : {};

  profilesToAdd.forEach(profile => {
    Object.entries(profile).forEach(([userId, userProfile]) => {
      if (!profiles[userId]) {
        profiles[userId] = { ...userProfile, products: [...userProfile.products] };
      } else {
        userProfile.products.forEach(newProduct => {
          const existingProduct = profiles[userId].products.find(p => p.contentCode === newProduct.contentCode);

          if (existingProduct) {
            existingProduct.licenseStatus = newProduct.licenseStatus;
            existingProduct.licenseExpirationDate = newProduct.licenseExpirationDate;
          } else {
            profiles[userId].products.push(newProduct);
          }
        });
      }
    });
  });

  localStorage.setItem('offline_profiles', JSON.stringify(profiles));

  yield put(loadOfflineProfiles(profiles));
}

export default addOfflineProfiles;
