import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Components
import { getHeaderColumnsAndValidationRules } from '@oup/shared-node-browser/bulkUsersValidationMapping.js';
import PanelScrollContainer from '../../../../../../../components/PanelScrollContainer/PanelScrollContainer';
import ScrollContainer from '../../../../../../../components/ScrollContainer/ScrollContainer.js';
import PopoutNavFooter from '../../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import withLocalizedContent from '../../../../../../../language/withLocalizedContent.js';
import SVGIcon, { GLYPHS } from '../../../../../../../components/SVGIcon/SVGIcon.js';
import Button, { buttonTypes } from '../../../../../../../components/Button/Button.js';
import UsersTable from './UsersTable.js';
import styles from './EnrolUserDataReviewing.scss';

// Actions
import { bulkReviewableFormSubmit } from '../../../../../../../redux/reducers/enrolUser.reducer.js';

const hasErrors = validationErrors =>
  Object.values(validationErrors).some(errorObject =>
    Object.values(errorObject).some(errorValue => errorValue === true)
  );

const filterTypes = {
  ALL_ROWS: 'ALL_ROWS',
  ERROR_ROWS: 'ERROR_ROWS'
};

function EnrolUserDataReviewing({
  options,
  closePanel,
  entries,
  localizedContent: { importUsersReview: content },
  enrolStudents
}) {
  const [filter, setFilter] = useState(filterTypes.ALL_ROWS);

  const { orgRole, userTypeToEnrol, isPlacementCentre } = options;
  const { expectedColumns, optionalColumns, validationRules } = getHeaderColumnsAndValidationRules(
    orgRole,
    userTypeToEnrol,
    isPlacementCentre
  );

  const totalCount = entries.length;
  const errorCount = useMemo(() => entries.filter(entry => hasErrors(entry.validationErrors)).length, [entries]);

  const filteredEntries = useMemo(() => {
    if (filter === 'ERROR_ROWS') {
      return entries.filter(entry => hasErrors(entry.validationErrors));
    }
    return entries;
  }, [entries, filter]);

  const handleFilterChange = newFilter => {
    setFilter(newFilter);
  };

  return (
    <PanelScrollContainer
      heading={content.panel_heading}
      subHeading={`${totalCount} ${content.panel_subheading}`}
      closeText="Close"
      closeAction={closePanel}
      customHeaderContentClass={styles.panelHeader}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.enrolUserReview}>
            <div className={styles.recordsCount}>
              <SVGIcon
                className={styles.glyph}
                glyph={errorCount === 0 ? GLYPHS.ICON_CHECK_CIRCLE : GLYPHS.ICON_ERROR_CIRCLE}
              />
              {errorCount === 0 || errorCount > 1
                ? `${errorCount} ${content.errors_found_text}`
                : `${errorCount} ${content.error_found_text}`}
            </div>
            <div>
              <Button
                type={buttonTypes.GROUP}
                text={content.all_rows_filter_button_text}
                active={filter === filterTypes.ALL_ROWS}
                onClick={() => handleFilterChange(filterTypes.ALL_ROWS)}
              />
              <Button
                type={buttonTypes.GROUP}
                text={content.errors_rows_only_filter_button_text}
                active={filter === filterTypes.ERROR_ROWS}
                onClick={() => handleFilterChange(filterTypes.ERROR_ROWS)}
              />
            </div>
          </div>
        }
        footerContent={
          <PopoutNavFooter
            backAction={closePanel}
            nextAction={() => enrolStudents(options)}
            nextButtonDisabled={errorCount > 0}
            nextButtonText="Import users"
          />
        }
      >
        <UsersTable
          options={options}
          entries={filteredEntries}
          expectedColumns={expectedColumns}
          optionalColumns={optionalColumns}
          validationRules={validationRules}
        />
      </ScrollContainer>
    </PanelScrollContainer>
  );
}

EnrolUserDataReviewing.propTypes = {
  options: PropTypes.object.isRequired,
  closePanel: PropTypes.func.isRequired,
  entries: PropTypes.array.isRequired,
  localizedContent: PropTypes.object.isRequired,
  enrolStudents: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('importUsersReview'),
  connect(
    state => ({
      entries: state.enrolUser.entries
    }),
    {
      enrolStudents: bulkReviewableFormSubmit
    }
  )
)(EnrolUserDataReviewing);
